import { Component, OnInit } from '@angular/core';
import { AppService } from './../../app.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.scss']
})
export class WorkshopComponent implements OnInit {
  workshops: any;
  workshops1: any;
  workshops2: any;
  constructor(
    public appService: AppService, private titleService: Title
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.titleService.setTitle('Workshops - ERAM International');
    this.appService.getWorkshops().subscribe(data => {
      console.log('ws', data);
      this.workshops = data;
      this.workshops1 = this.workshops.filter(w1 => w1.semestre == 1);
      this.workshops2 = this.workshops.filter(w2 => w2.semestre == 2);
    });
  }

}
