<div class="work">
  <div class="slide" (click)="open(content, workplay)">
    <div class="aspect" *ngIf="workplay.photo">
      <img src="https://images.eram.cat/slir/w480-c2x2/workplay/{{workplay.photo}}">
    </div>
    <div class="aspect" *ngIf="!workplay.photo" style="background-image: url(../../../assets/img/default.jpeg);">
    </div>
    <div class="peu text-right" *ngIf="!work.table">
      <span class="heart" *ngIf="checkLike('workplay',workplay.id) && this.appService.user.token"><i
          class="fa fa-heart"></i></span>
      <span class="heart" *ngIf="!checkLike('workplay',workplay.id) && this.appService.user.token"><i
          class="fa fa-heart-o"></i></span>
      <span class="heart" *ngIf="!this.appService.user.token"><i class="fa fa-heart-o"></i></span>
      <span class="more">+</span>
    </div>
    <div class="peu peu-fixed text-right" *ngIf="work.table">
      <span class="heart" *ngIf="checkLike('workplay',workplay.id) && this.appService.user.token"><i
          class="fa fa-heart"></i></span>
      <span class="heart" *ngIf="!checkLike('workplay',workplay.id) && this.appService.user.token"><i
          class="fa fa-heart-o"></i></span>
      <span class="heart" *ngIf="!this.appService.user.token"><i class="fa fa-heart-o"></i></span>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header"></div>
  <div class="modal-body vimeo">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="video-vimeo" *ngIf="modalslide">
      <!-- {{modalslide | json}} -->
      <div class='embed-container' *ngIf="modalslide.vimeoid">
        <iframe class="embed-responsive-item"
          [src]="'https://player.vimeo.com/video/'+modalslide.vimeoid  | safe: 'resourceUrl'" allowfullscreen></iframe>
      </div>
      <div class='embed-container' *ngIf="modalslide.youtube">
        <iframe class="embed-responsive-item"
          [src]="'https://www.youtube.com/embed/'+modalslide.youtube  | safe: 'resourceUrl'" allowfullscreen></iframe>
      </div>
    </div>
    <div class="info-medit">
      <div class="row">
        <div class="col-12 col-md-9">
          <h3 *ngIf="modalslide.title">{{modalslide.title}}</h3>
          <!-- <h4 *ngIf="modalslide.author">{{modalslide.author}}</h4> -->
          <p *ngIf="modalslide.description">{{modalslide.description}}</p>
        </div>
        <div class="col-12 col-md-3 text-right">
          <span class="heart" *ngIf="checkLike('workplay',modalslide.id) && this.appService.user.token"
            (click)="like(modalslide.id,'workplay',appService.user,null)"><i class="fa fa-heart fa-2x"></i></span>
          <span class="heart" *ngIf="!checkLike('workplay',modalslide.id) && this.appService.user.token"
            (click)="like(modalslide.id,'workplay',appService.user,null)"><i class="fa fa-heart-o fa-2x"></i></span>
          <span class="heart" *ngIf="!this.appService.user.token" (click)="openlogin(contentlogin)"><i
              class="fa fa-heart-o fa-2x"></i></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentwork let-modal>
  <div class="modal-header"></div>
  <div class="modal-body vimeo">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="video-vimeo" *ngIf="modalslide">
      <!-- {{modalslide | json}} -->
      <div class='embed-container'>
        <iframe [src]="vimeoUrl(modalslide.vimeoid)" *ngIf="modalslide" width="640" height="360" frameborder="0"
          allowfullscreen></iframe>
      </div>
    </div>
    <div class="info-medit">
      <div class="row">
        <div class="col-12 col-md-9">
          <h3 *ngIf="modalslide.title">{{modalslide.title}}</h3>
          <!-- <h4 *ngIf="modalslide.author">{{modalslide.author}}</h4> -->
          <p *ngIf="modalslide.description">{{modalslide.description}}</p>
        </div>
        <div class="col-12 col-md-3 text-right">
          <span class="heart" *ngIf="checkLike('workplay',modalslide.id) && this.appService.user.token"
            (click)="like(modalslide.id,'workplay',appService.user,null)"><i class="fa fa-heart fa-2x"></i></span>
          <span class="heart" *ngIf="!checkLike('workplay',modalslide.id) && this.appService.user.token"
            (click)="like(modalslide.id,'workplay',appService.user,null)"><i class="fa fa-heart-o fa-2x"></i></span>
          <span class="heart" *ngIf="!this.appService.user.token" (click)="openlogin(contentlogin)"><i
              class="fa fa-heart-o fa-2x"></i></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentlogin let-modallogin>
  <div class="modal-body vimeo">
    <button type="button" class="close" aria-label="Close" (click)="modallogin.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="info-work">
      <div class="row justify-content-center">
        <div class="col-12 col-md-11">
          <h3>Login</h3>
          <form (ngSubmit)="loginUser()" *ngIf="!registerForm">
            <div class="row">
              <div class="col-12 mb-3 errors" *ngIf="errors">
                <p class="alert alert-error">{{errors.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" placeholder="email" name="email" [(ngModel)]="login.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" placeholder="password" name="password"
                  [(ngModel)]="login.password">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Login">
                <span class="btn btn-white-bg" (click)="showRegister()">Register new user</span>
              </div>
            </div>
            {{message_login | json}}
          </form>
          <form (ngSubmit)="userRegister()" *ngIf="registerForm">
            <div class="row">
              <div class="col-12 mb-3 errors" *ngIf="message">
                <p class="alert alert-error" *ngIf="!message.message">{{message}}</p>
                <p class="alert alert-error" *ngIf="message.message">{{message.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" required placeholder="Your email" name="email"
                  [(ngModel)]="register.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" required placeholder="Your password" name="password"
                  [(ngModel)]="register.password">
              </div>
              <div class="col-12">
                <input type="text" class="form-control" required placeholder="Your name" name="name"
                  [(ngModel)]="register.name">
              </div>
              <div class="col-12 col-md-7">
                <input type="text" class="form-control" placeholder="Your lastname" name="lastname"
                  [(ngModel)]="register.lastname">
              </div>
              <div class="col-12 col-md-5">
                <input type="text" class="form-control" placeholder="Your phone" name="phone"
                  [(ngModel)]="register.phone">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Register">
                <span class="btn btn-white-bg" (click)="showLogin()">Return to login</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>