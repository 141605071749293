import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppService } from '../app.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ngxLightOptions } from 'ngx-light-carousel/public-api'
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  //heroes: Hero[] = [];
  slidesloaded = 0;
  showSlides = false;
  slides: any = [];
  mediterranean: any = [];
  teachers: any = [];
  subjects: any = [];
  workplay: any = [];
  professors: any = [];
  modallogin: any = {};
  loggg: any;
  errors: any;
  registerForm: Boolean = false;
  message: any;
  message_login: any;
  register = {
    email: null,
    password: null,
    name: null,
    lastname: null,
    phone: null
  };
  options: any;
  login = {
    email: '',
    password: ''
  }
  width: number = 1700;
  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": '.arrow-next',
    "prevArrow": '.arrow-prev',
    "autoplay": '9900',
    "infinite": true
  };
  mediterraneanConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "nextArrow": '.mediterranean-next',
    "prevArrow": '.mediterranean-prev',
    "infinite": true,
    "responsive": [
      {
        "breakpoint": 768,
        "settings": {
          "slidesToShow": 2
        }
      },
      {
        "breakpoint": 1100,
        "settings": {
          "slidesToShow": 4
        }
      }
    ]
  };
  profesConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "nextArrow": '.teachers-next',
    "prevArrow": '.teachers-prev',
    "infinite": true,
    "responsive": [
      {
        "breakpoint": 768,
        "settings": {
          "slidesToShow": 2
        }
      },
      {
        "breakpoint": 1100,
        "settings": {
          "slidesToShow": 4
        }
      }
    ]
  };
  closeResult = '';

  constructor(public appService: AppService, private modalService: NgbModal, private sanitizer: DomSanitizer, private activeModal: NgbActiveModal, private titleService: Title) {
    this.options = {
      animation: {
        animationClass: 'transition',
        animationTime: 500,
      },
      swipe: {
        swipeable: true,
        swipeVelocity: .4,
      },
      drag: {
        draggable: true,
        dragMany: true,
      },
      arrows: true,
      infinite: false,
      scroll: {
        numberToScroll: 1
      },
      autoplay: {
        enabled: true,
        direction: 'right',
        delay: 5000,
        stopOnHover: true
      },
      breakpoints: [
        {
          width: 768,
          number: 2,
        },
        {
          width: 991,
          number: 4,
        },
        {
          width: 9999,
          number: 5,
        },
      ],
    }
  }

  ngOnInit() {
    this.titleService.setTitle('Home - ERAM International');
    window.scroll(0, 0);
    this.width = window.innerWidth;
    this.getSlides();
    this.teachers = '';
    this.teachers = this.appService.teachers;
    this.shuffle(this.teachers);
    if (!this.teachers.find(x => x.id == 0)) {
      this.teachers.splice(1, 0, { 'id': 0, 'letter': 'R' });
    }
    this.mediterranean = this.appService.mediterranean;
    if (!this.mediterranean.find(x => x.id == 0)) {
      this.mediterranean.splice(2, 0, { 'id': 0 });
    }
    this.subjects = this.appService.subjects;
    this.shuffle(this.subjects);
    if (!this.subjects.find(x => x.id == 0)) {
      this.subjects.splice(3, 0, { 'id': 0 });
    }
    this.workplay = this.appService.workplay;
    if (!this.workplay.find(x => x.id == 0)) {
      this.workplay.splice(1, 0, { 'id': 0 });
    }

  }
  getSlides(): void {
    this.appService.getSlides().subscribe(data => {
      this.slides = data;
    });
  }

  checkCat(id) {
    return this.appService.category.find(
      c => c.category_fk == id
    );
  }
  loaded(type) {
    console.log('type', type);
    if (type == 'slides') {
      this.slidesloaded++;
      this.showSlides = this.slidesloaded >= (this.slides.length - 1) ? true : false;
    }
    console.log('type', this.slides.length);

  }
  // vimeoUrl(id){
  //   return this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/'+id+'?autoplay=false');
  // }



  showRegister() {
    this.registerForm = true;
  }
  showLogin() {
    this.registerForm = false;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  openlogin(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  userRegister() {
    console.log('form', this.register);
    if (this.register.email && this.register.name && this.register.lastname && this.register.password.length >= 5) {
      this.appService.register(this.register).subscribe(data => {
        console.log('log', data);
        this.message = data;
      });
    } else {
      this.message = 'Complete all fields';
      console.log('error');
    }
  }
  loginUser() {
    this.appService.login(this.login).subscribe(data => {
      console.log('login', data);
      this.appService.updateUser(data);
      this.message_login = data;
      this.modalService.dismissAll();
      this.appService.getUserLikes(this.appService.user.user.id).subscribe(data => {
        console.log('likeeeees', data);
        this.appService.userLikes = data;
      });
    },
      (err) => {
        console.log('err', err);
        this.errors = err;
        this.appService.user.token = null;
        this.appService.user.user = null;
      }
    );
  }
  checkLike(table, id) {
    if (this.appService.user.token) {
      var ex = this.appService.userLikes.filter(like => like.table_id == id && like.table == table);
      if (ex.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}
