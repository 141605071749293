<div class="slide-teacher-detail" (click)="openTeacher(contentteacher, profe)"
  *ngIf="professor && appService.professors">
  <div class=" foto" *ngIf="professor.foto || !profe.photo">
    <img src="https://images.eram.cat/slir/w450-c3x4-p1/persona/{{professor.foto[0].url}}" (load)="loaded()"
      *ngIf="!profe.photo && professor.foto[0]">
  </div>
  <div class="foto" *ngIf="profe.photo">
    <img src="https://images.eram.cat/slir/w450-c3x4-p1/persona/{{profe.photo}}" (load)="loaded()" *ngIf="profe.photo">
  </div>
  <!-- <div class="peu">
    <h3>{{professor.nom}} {{professor.cog1}} {{professor.cog2}}</h3>
  </div> -->
</div>
<div class="slide-teacher-detail" (click)="openTeacher(contentteacher, profe)"
  *ngIf="profe && !professor && appService.professors">
  <div class="foto" *ngIf="profe.photo">
    <img src="https://images.eram.cat/slir/w450-c3x4-p1/persona/{{profe.photo}}" (load)="loaded()" *ngIf="profe.photo">
  </div>
  <!-- <div class="peu">
    <h3>{{professor.nom}} {{professor.cog1}} {{professor.cog2}}</h3>
  </div> -->
</div>
<ng-template #contentteacher let-modal>
  <div class="modal-teacher">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-10">
              <h3>{{modalteacher.professor.nom}} {{modalteacher.professor.cog1}} {{modalteacher.professor.cog2}}</h3>
            </div>
            <div class="col-2 text-right">
              <app-like [like]="modalteacher" [table]="'teacher'"></app-like>
            </div>
          </div>

          <h5 class="category" *ngIf="modalteacher.professor.profile.en">{{modalteacher.professor.profile.en}}</h5>
          <h4 class="frase" *ngIf="modalteacher.slogan">{{modalteacher.slogan}}</h4>
        </div>
        <div class="col-12 col-md-7 info">
          <div class="text introduction" *ngIf="modalteacher.introduction" [innerHTML]="modalteacher.introduction">
          </div>
          <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
            <ngb-panel class="subjects-content" id="config-panel-one" *ngIf="modalteacher.assignatures.length > 0">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0" ngbPanelToggle><button ngbPanelToggle>Subjects</button></h5>
                  <button ngbPanelToggle class="btn btn-link p-0">{{ opened ? '-' : '+' }}</button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent class="">
                <p *ngFor="let s of modalteacher.assignatures"><a
                    href="https://www.eram.cat/en/studies/degree-in-audiovisual-communication-and-multimedia/study-plan-gcam/{{s.mr.en}}"
                    target="_blank">{{s.title.en}}</a></p>
              </ng-template>
            </ngb-panel>
            <ngb-panel class="cv-content" *ngIf="modalteacher.professor.curriculum.en">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0" ngbPanelToggle><button ngbPanelToggle>Curriculum</button></h5>
                  <button ngbPanelToggle class="btn btn-link p-0">{{ opened ? '-' : '+' }}</button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent class="">
                <div class="text" [innerHTML]="modalteacher.professor.curriculum.en"></div>
              </ng-template>
            </ngb-panel>
            <ngb-panel class="cv-content" *ngIf="modalteacher.professor.trajectoria.en">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0" ngbPanelToggle><button ngbPanelToggle>Professional Career</button></h5>
                  <button ngbPanelToggle class="btn btn-link p-0">{{ opened ? '-' : '+' }}</button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent class="">
                <div class="text" [innerHTML]="modalteacher.professor.trajectoria.en"></div>
              </ng-template>
            </ngb-panel>
            <ngb-panel class="cv-content" *ngIf="modalteacher.research && modalteacher.research.length>0">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0" ngbPanelToggle><button ngbPanelToggle>Research</button></h5>
                  <button ngbPanelToggle class="btn btn-link p-0">{{ opened ? '-' : '+' }}</button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent class="">
                <div class="text">
                  <p *ngFor="let r of modalteacher.research">
                    {{r.any}} - <span style="color:#f14850">{{r.title}}</span> {{r.type}} {{r.isbn}} {{r.revista}}
                    {{r.editorial}} <span *ngIf="r.authors" style="color:white">, {{r.authors}}</span>
                  </p>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel class="cv-content" *ngIf="modalteacher.news && modalteacher.news.length>0">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0" ngbPanelToggle><button ngbPanelToggle>News</button></h5>
                  <button ngbPanelToggle class="btn btn-link p-0">{{ opened ? '-' : '+' }}</button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent class="">
                <div class="text">
                  <p *ngFor="let r of modalteacher.news"><a [href]="'https://eram.cat/'+r.slug"
                      target="_blank">{{r.title}}</a></p>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <form class="rate">
            <div class="row align-items-center">
              <div class="col-12">
                <h5>Rate the poster!</h5>
              </div>
              <div class="col-12">
                <ngb-rating [(rate)]="selected" name="rate" [(ngModel)]="feedbackteacher.answer"></ngb-rating>
                <input (click)="postFeedbackTeacher('teacher',modalteacher.id,feedbackteacher.answer)"
                  *ngIf="!answeredteacher" class="btn" type="submit">
                <span class="voted" *ngIf="answeredteachertext">Thanks for your vote!</span>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-5 text-center">
          <div class="foto">
            <img src="https://images.eram.cat/slir/w600-p1/persona/{{modalteacher.photo}}" loading="lazy"
              *ngIf="modalteacher.photo">
            <img src="https://images.eram.cat/slir/w600-p1/persona/{{modalteacher.professor.foto.url}}" loading="lazy"
              *ngIf="!modalteacher.photo && modalteacher.professor.foto.url">
          </div>
        </div>
        <div class="col-12">

        </div>
        <div class="col-12 related" *ngIf="modalteacher.works.length>0">
          <h4>Related</h4>
          <div class="works">
            <ngx-slick-carousel class="works-carousel" [config]="worksConfig">
              <div ngxSlickItem *ngFor="let work of modalteacher.works;let i = index" class="carro">
                <div class="content">
                  <a [href]="'https://www.eram.cat/en/showroom/'+work.mr.en" target="_blank">
                    <div class="photo">
                      <img loading="lazy" [src]="'https://images.eram.cat/slir/w300-c1x1/treball/'+work.photo">
                    </div>
                    <div class="footer text-center">
                      <h5>{{work.title.en ? work.title.en : work.title.ca}}</h5>
                    </div>
                  </a>
                </div>
              </div>
            </ngx-slick-carousel>
            <div class="arrows-prev work-prev"><i class="fa fa-angle-left"></i></div>
            <div class="arrows-next work-next"><i class="fa fa-angle-right"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>