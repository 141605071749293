import { Component, OnInit, ViewChild } from "@angular/core";
import { AppService } from "../../app.service";
import { NgxMasonryOptions, NgxMasonryComponent } from "ngx-masonry";

@Component({
  selector: "app-favorites",
  templateUrl: "./favorites.component.html",
  styleUrls: ["./favorites.component.scss"],
})
export class FavoritesComponent implements OnInit {
  public masonryOptions: NgxMasonryOptions = {
    gutter: 0,
  };

  masonryItems = [
    {
      title: "item 1",
      foto: "https://images.eram.cat/slir/w350-h556/persona/JUDIT_VIDIELLA-1614334618946.jpg",
    },
    {
      title: "item 2",
      foto: "https://images.eram.cat/slir/w350-h556/persona/JUDIT_VIDIELLA-1614334618946.jpg",
    },
    {
      title: "item 3",
      foto: "https://images.eram.cat/slir/w350-h556/persona/JUDIT_VIDIELLA-1614334618946.jpg",
    },
    {
      title: "item 1",
      foto: "https://images.eram.cat/slir/w350-h556/persona/JUDIT_VIDIELLA-1614334618946.jpg",
    },
    {
      title: "item 2",
      foto: "https://images.eram.cat/slir/w350-h556/persona/JUDIT_VIDIELLA-1614334618946.jpg",
    },
    {
      title: "item 3",
      foto: "https://images.eram.cat/slir/w350-h556/persona/JUDIT_VIDIELLA-1614334618946.jpg",
    },
  ];
  favorites: any;
  loading: boolean = false;
  message: unknown;

  constructor(public appService: AppService) {}
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  ngOnInit(): void {
    console.log(this.appService.user);
    if (sessionStorage.user_token) {
      // console.log(sessionStorage.user_token);
      this.appService.user.token = sessionStorage.user_token;
    }
    if (sessionStorage.user_id > 0) {
      this.message = "favorites works";
      this.appService.getUserLikes(sessionStorage.user_id).subscribe((data) => {
        this.favorites = data;
        for (var i = 0; i < this.favorites.length; i++) {
          if (this.favorites[i].table == "teacher") {
            var res = this.appService.teachers.filter(
              (p) => p.id == this.favorites[i].table_id
            )[0];
            //console.log('res',res);
            this.favorites[i].teacher_fk = res.teacher_fk;
            this.favorites[i].photo = res.photo;
          }
        }
        this.loading = true;
      });
    } else {
      this.message = "you have to login";
    }
  }
  imageLoad(item) {
    console.log("loaded", item);
    this.masonry.layout();
  }
  showStudy(data) {
    var dada = JSON.parse(data);
    var study = "";
    switch (dada.study) {
      case "1Y":
        study = "1 Year";
        break;
      case "D":
        study = "Degree";
        break;
      case "1S":
        study = "1st Semester";
        break;
      case "2S":
        study = "2n Semester";
        break;
      case "W":
        study = "Workshop";
        break;
    }
    return study;
  }
  showCats(data) {
    var dada = JSON.parse(data);
    var cats = dada.cat ? dada.cat.split(",") : [];
    var res = "";
    if (cats.length > 0) {
      for (var i = 0; i < cats.length; i++) {
        res +=
          "<p>" +
          this.appService.categories.filter((x) => x.categoria_id == cats[i])[0]
            .categoria_nom_en +
          "</p>";
      }
    }
    return res;
  }
}
