import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SafePipeModule } from 'safe-pipe';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header.component';

import { HomeComponent } from './home/home.component';
import { IconCategoryComponent } from './components/IconCategory.component';
import { TeacherSlideComponent } from './components/teacherSlide.component';
import { TeacherSlideInfoComponent } from './components/teacherSlideInfo.component';
import { SubjectSlideComponent } from './components/SubjectSlide.component';
import { SubjectSlideInfoComponent } from './components/SubjectSlideInfo.component';

import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxMasonryModule } from 'ngx-masonry';
import { AgmCoreModule } from '@agm/core';
import { NgxCarouselModule } from 'ngx-light-carousel';
import { Angulartics2Module } from 'angulartics2';

import { TeamComponent } from './team/team.component';
import { StudiesComponent } from './studies/studies.component';
import { ContactComponent } from './contact/contact.component';
import { WorkshopComponent } from './studies/workshop/workshop.component';
import { DegreeComponent } from './studies/degree/degree.component';
import { YearComponent } from './studies/year/year.component';
import { HalfComponent } from './studies/half/half.component';
import { UserComponent } from './user/user.component';
import { ProfileComponent } from './user/profile/profile.component';
import { FavoritesComponent } from './user/favorites/favorites.component';
import { FooterComponent } from './common/footer.component';
import { MediterraneanComponent } from './components/mediterranean/mediterranean.component';
import { WorkplayComponent } from './components/workplay/workplay.component';
import { LocationComponent } from './about/location/location.component';
import { UdgComponent } from './about/udg/udg.component';
import { EramComponent } from './about/eram/eram.component';
import { LikeComponent } from './components/like/like.component';
import { WorkComponent } from './work/work.component';
import { WorkDetailComponent } from './work-detail/work-detail.component';
import { ExpoComponent } from './expo/expo.component';
import { ExpoDetailComponent } from './expo-detail/expo-detail.component';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'tour-of-heroes' }),
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    NgxMasonryModule,
    BrowserAnimationsModule,
    NgxCarouselModule,
    ReactiveFormsModule,
    SafePipeModule,
    Angulartics2Module.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC9xKcya7g9YjD6aFpNxhEo0kKRoweTOd4'
    }),
    NgbModule
  ],
  providers: [
    NgbActiveModal,
    Title
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    TeacherSlideComponent,
    TeacherSlideInfoComponent,
    SubjectSlideComponent,
    SubjectSlideInfoComponent,
    IconCategoryComponent,
    TeamComponent,
    StudiesComponent,
    ContactComponent,
    WorkshopComponent,
    DegreeComponent,
    YearComponent,
    HalfComponent,
    UserComponent,
    ProfileComponent,
    FavoritesComponent,
    FooterComponent,
    MediterraneanComponent,
    WorkplayComponent,
    LocationComponent,
    UdgComponent,
    EramComponent,
    LikeComponent,
    WorkComponent,
    WorkDetailComponent,
    ExpoComponent,
    ExpoDetailComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
