<div class="slide-subject" (click)="openSubject(contentsubject, subject)" *ngIf="assignatura && subject">
  <div class="foto" *ngIf="assignatura.photo[0] && !subject.photo">
    <img src="https://images.eram.cat/slir/w370-c1x1-p1/assignatura/{{assignatura.photo[0].url}}" loading="lazy"
      *ngIf="!subject.photo && assignatura.photo[0].url">
  </div>
  <div class="foto" *ngIf="subject.photo">
    <img src="https://images.eram.cat/slir/w370-c1x1-p1/assignatura/{{subject.photo}}" loading="lazy"
      *ngIf="subject.photo">
  </div>
  <div class="peu">
    <div class="row align-items-center justify-content-center">
      <div class="col-9 title">
        {{assignatura.nom.en}}
      </div>
      <div class="col-3 text-right">
        <span class="heart" *ngIf="checkLike('subject',subject.id) && this.appService.user.token"><i
            class="fa fa-heart"></i></span>
        <span class="heart" *ngIf="!checkLike('subject',subject.id) && this.appService.user.token"><i
            class="fa fa-heart-o"></i></span>
        <span class="heart" *ngIf="!this.appService.user.token"><i class="fa fa-heart-o"></i></span>
      </div>
      <!-- <span class="more">+</span> -->
    </div>
  </div>
</div>


<ng-template #contentsubject let-modal>
  <div class="modal-subject">
    <div class="modal-header"
      style="background-image: url(https://images.eram.cat/slir/w1200-p1/assignatura/{{subject.assignatura.photo[0].url}});">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 title">
          <div class="categories">
            <div class="cat" *ngFor="let i of subject.categories">
              <app-iconCategory [cat]="i" *ngIf="checkCat(i.categoria_id)"></app-iconCategory>
            </div>
          </div>
          <div class="row">
            <div class="col-10">
              <h3>{{subject.assignatura.nom.en}}</h3>
            </div>
            <div class="col-2 text-right">
              <app-like [like]="subject" [table]="'subject'"></app-like>
            </div>
          </div>

          <p class="dades">
            <span class="ects">{{subject.assignatura.credits}} ECTS</span>
            <span class="course">{{subject.assignatura.curs}}<ng-container *ngIf="subject.assignatura.curs==1">st
              </ng-container>
              <ng-container *ngIf="subject.assignatura.curs==2">nd</ng-container>
              <ng-container *ngIf="subject.assignatura.curs==3">rd</ng-container>
              <ng-container *ngIf="subject.assignatura.curs>3">th</ng-container>
              course
            </span>
            <span class="semestre" *ngIf="subject.assignatura.durada=='S'">{{subject.assignatura.semestre}}
              semester</span>
            <span class="semestre" *ngIf="subject.assignatura.durada=='A'">Anual </span>
          </p>
        </div>
        <div class="col-12 cats">
          <div class="tags" *ngIf="subject.categories && checkCats(subject.categories)==0">
            <span *ngFor="let c of subject.categories">#{{c.categoria_nom_en}}</span>
          </div>
          <div class="tags" *ngIf="subject.categories && checkCats(subject.categories)>0">
            <span>#Research</span>
          </div>
        </div>
        <div class="col-12 info">
          <div class="descripcio text" *ngIf="subject.assignatura.text.en" [innerHTML]="subject.assignatura.text.en">
          </div>
        </div>
        <div class="col-12 col-md-6 skills" *ngIf="subject.skills">
          <h4>Skills</h4>
          <div class="skill text" [innerHTML]="subject.skills"></div>
        </div>
        <div class="col-12 col-md-6 career" *ngIf="subject.career">
          <h4>Career opportunities</h4>
          <div class="career text" [innerHTML]="subject.career"></div>
        </div>
        <div class="col-12 question" *ngIf="!answered">
          <div class="row align-items-center">
            <div class="col">
              <h5>When I finish my studies at ERAM I want to work as a ...</h5>
            </div>
            <select [(ngModel)]="feedback.answer" class="col-8 col-md-4 form-control">
              <option value="null">Select...</option>
              <option value="Filmmaker">Filmmaker</option>
              <option value="Graphic design">Graphic Designer</option>
              <option value="Photographer">Photographer</option>
              <option value="Camera operator">Camera operator</option>
              <option value="Interactive designer">Interactive designer</option>
            </select>
            <div class="col-4 col-md-2"><button class="btn"
                (click)="postFeedback('subject',subject.id,feedback.answer)">Send</button></div>
          </div>
        </div>
        <div class="col-12 question" *ngIf="answered">
          <h5>When I finish my studies at ERAM I want to work as a <span>{{feedback.answer}}</span></h5>
        </div>

      </div>
    </div>
  </div>
</ng-template>