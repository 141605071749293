import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import $ from "jquery";
import { NgbAccordionConfig, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from '../app.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-teacherSlideInfo',
  templateUrl: './teacherSlideInfo.component.html',
  styleUrls: ['./teacherSlideInfo.component.scss'],
  providers: [NgbAccordionConfig, NgbRatingConfig]
})
export class TeacherSlideInfoComponent implements OnInit {
  @Input() profe: any;
  professor: any;
  modalteacher: any = {};
  closeResult = '';
  moremore: Boolean = true;
  ctrl = new FormControl(null, Validators.required);
  selected = 0;
  feedbackteacher: any = { answer: null };
  answeredteacher: boolean = false;
  answeredteachertext: boolean = false;
  worksConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": '.work-next',
    "prevArrow": '.work-prev',
    "infinite": true,
    "responsive": [
      {
        "breakpoint": 768,
        "settings": {
          "slidesToShow": 2
        }
      },
      {
        "breakpoint": 1100,
        "settings": {
          "slidesToShow": 4
        }
      }
    ]
  };
  constructor(
    private route: ActivatedRoute,
    public appService: AppService, private modalService: NgbModal, private activeModal: NgbActiveModal,
    config: NgbAccordionConfig,
    configr: NgbRatingConfig
  ) {
    config.closeOthers = true;
    configr.max = 5;
  }

  ngOnInit(): void {
    //console.log('input profe', this.profe);
    this.professor = this.appService.professors.filter(p => p.id == this.profe.teacher_fk);
    this.professor = this.professor[0];
  }
  openTeacher(contentteacher, teacher) {
    console.log('modal slide', this.modalteacher);
    this.modalteacher.id = teacher.id;
    this.modalteacher.photo = teacher.photo;
    this.modalteacher.slogan = teacher.slogan;
    this.modalteacher.introduction = teacher.introduction;
    console.log('teacher', teacher);
    console.log('appservice pro teacher', this.appService.professors.filter(p => p.id == teacher.teacher_fk));
    this.modalteacher.professor = this.appService.professors.filter(p => p.id == teacher.teacher_fk)[0];
    this.modalteacher.assignatures = [];
    this.modalteacher.works = [];
    this.moremore = true;
    this.appService.getTeacherWorks(this.modalteacher.professor.id).subscribe(data => {
      //console.log('works', data);
      this.modalteacher.works = data;
    });
    $('.modal .text').addClass('more');
    //agafem assignatures i fem loop

    if (this.modalteacher.professor.subjects) {
      var subjects = this.modalteacher.professor.subjects;
      for (var j = 0; j < subjects.length; j++) {
        console.log('subjects', subjects[j]);
        let assig = this.appService.assignatures.filter(p => p.imparticio_id == subjects[j]);
        console.log('p', assig);
        if (assig[0]) {
          console.log('assigs', assig[0]);
          console.log('assigs', this.modalteacher.assignatures);

          let array = this.modalteacher.assignatures.filter(a => a.imparticio_id == assig[0].imparticio_id);
          if (!array[0]) {
            this.modalteacher.assignatures.push(assig[0]);
          }

        }
      }
      let seen = new Set();
      const filteredArr = this.modalteacher.assignatures.filter(el => {
        const duplicate = seen.has(el.assignatura_id);
        seen.add(el.assignatura_id);
        return !duplicate;
      });
      console.log(filteredArr);
      this.modalteacher.assignatures = filteredArr;
    }
    //busquem si té recerca
    // this.appService.getTeachersResearch(this.modalteacher.professor.id).subscribe(data => {
    //   this.modalteacher.research = data;
    // });
    // //busquem si té noticies
    // this.appService.getTeachersNews(this.modalteacher.professor.id).subscribe(data => {
    //   this.modalteacher.news = data;
    // });

    console.log('modal', this.modalteacher);
    this.modalService.open(contentteacher, { size: 'xl', scrollable: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  loaded() {
    this.appService.teachersloaded++;
    var teachs = this.appService.teachers.filter(m => m.active == 1);
    console.log(teachs.length);
    console.log('loaded teacher', this.appService.teachersloaded);
    this.appService.teacherscomplete = (this.appService.teachersloaded) >= (teachs.length - 1) ? true : false;
  }
  readMore() {
    $('.modal .text').toggleClass('more');
    this.moremore = !this.moremore;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  checkLike(table, id) {
    if (this.appService.user.token) {
      var ex = this.appService.userLikes.filter(like => like.table_id == id && like.table == table);
      if (ex.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  postFeedbackTeacher(table, table_fk, answer) {
    console.log(answer);
    if (answer != null) {
      this.appService.postFeedback(table, table_fk, answer).subscribe(result => {
        if (result) {
          this.answeredteacher = true;
          this.answeredteachertext = true;
        }
      });
    } else {
      alert('SELECT AN OPTION PLEASE');
    }
  }
}
