<section class="expo-single">
   <div class="container" *ngIf="id && item">
      <div class="" *ngIf="getData(id)">
         <div class="row">
            <div class="col-12 text-rights">
               <p><a [routerLink]="['/workplay/exposition']"><i class="fa fa-long-arrow-left fa-3x"></i></a></p>
            </div>
            <div class="col-12 col-md-6 info">
               <h5>Invited Designer</h5>
               <h1>{{poster.author}}</h1>
               <h3>{{poster.job}}</h3>
               <h4>{{poster.location}}</h4>
               <div class="text">
                  <p>{{poster.description}}</p>
               </div>
               <div class="contact">
                  <div class="profile">
                     <img [src]="'https://images.eram.cat/slir/w300-q100-c1x1/exposition/'+poster.profile"
                        alt="profile">
                  </div>
                  <h6 *ngIf="poster.contact_web || poster.contact_email">Contact</h6>
                  <p *ngIf="poster.contact_web">{{poster.contact_web}}</p>
                  <p *ngIf="poster.contact_email"><a
                        [href]="'mailto:'+poster.contact_email">{{poster.contact_email}}</a></p>
               </div>
            </div>
            <div class="col-12 col-md-6 poster">
               <img [src]="'https://images.eram.cat/slir/w700-p1-q100/exposition/'+poster.poster" alt="Poster"
                  *ngIf="poster.poster">
               <h2 class="title"><span>Title of work:</span><br>{{poster.title}}</h2>
               <div class="share">
                  <h6>Share on:</h6>
                  <p>
                     <span><a href="#" target="_blank"><i class="fa fa-facebook"></i></a></span>
                     <span><a href="#" target="_blank"><i class="fa fa-twitter"></i></a></span>
                     <span><a href="#" target="_blank"><i class="fa fa-pinterest"></i></a></span>
                     <span><a href="#" target="_blank"><i class="fa fa-linkedin"></i></a></span>
                  </p>
               </div>
            </div>
            <div class="col-12" *ngIf="poster.gallery.length>0">
               <h3>Portfolio</h3>
               <div class="row portfolio">
                  <div class="col-6 col-md-3" *ngFor="let p of poster.gallery;let i = index">
                     <a href="https://images.eram.cat/slir/w900-p1-q100/exposition/{{p}}" data-lightbox="image"
                        data-title="{{poster.author}}">
                        <img [src]="'https://images.eram.cat/slir/w400-c1x1-p1-q100/exposition/'+p" alt="Poster">
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>