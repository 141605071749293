<section *ngIf="page" class="workitem">
    <div class="container">
        <div class="text-rights">
            <p><a (click)="backClicked()"><i class="fa fa-long-arrow-left fa-3x"></i></a></p>
        </div>
        <div class="row justify-content-center">
            <div class="col-11" *ngIf="page.vimeoid">
                <div class='embed-container' *ngIf="page.vimeoid">
                    <iframe class="embed-responsive-item"
                        [src]="'https://player.vimeo.com/video/'+page.vimeoid  | safe: 'resourceUrl'"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-11" *ngIf="page.youtube">
                <div class='embed-container' *ngIf="page.youtube">
                    <iframe class="embed-responsive-item"
                        [src]="'https://www.youtube.com/embed/'+page.youtube  | safe: 'resourceUrl'"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-8 col-md-4" *ngIf="!page.vimeoid && !page.youtube">
                <img src="https://images.eram.cat/slir/w430-p1/workplay/{{page.photo}}">
            </div>
            <div class="col-12 col-md-8">
                <h1>{{page.title}}</h1>
                <div class="text" [innerHTML]="page.description"></div>
            </div>
        </div>
    </div>
</section>