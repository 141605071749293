<section class="slides container-fluid" *ngIf="slides">
  <ngx-slick-carousel class="carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <div class="item">
        <img src="https://images.eram.cat/slides/{{ slide.photo }}" loading="lazy" alt="" width="100%"
          (load)="loaded('slides')">
      </div>
    </div>
  </ngx-slick-carousel>
  <div class="arrow-prev"><i class="fa fa-chevron-circle-left"></i></div>
  <div class="arrow-next"><i class="fa fa-chevron-circle-right"></i></div>
</section>

<section class="mediterranean container-fluid" *ngIf="showSlides">
  <h2 class="heading">Mediterranean Experience</h2>
  <div class="slider carousel">
    <ngx-light-carousel [options]="options" [data]="appService.mediterranean" class="wrapper c-accent"
      style="display: block;">
      <section ngx-light-carousel-container class="content">
        <article ngx-light-carousel-item *ngFor="let slide of appService.mediterranean; let i = index">
          <div class="letter" *ngIf="slide.id==0">
            <div class="lett">E</div>
          </div>
          <app-mediterranean style="width:100%" *ngIf="slide.id>0" [medit]="slide" (load)="loaded('mediterranean')">
          </app-mediterranean>
        </article>
        <ng-template #infiniteContainer></ng-template>
      </section>
      <ng-template #carouselPrev>
        <div class="click-area">
          <i class="fa fa-angle-left"></i>
        </div>
      </ng-template>
      <ng-template #carouselNext>
        <div class="click-area">
          <i class="fa fa-angle-right"></i>
        </div>
      </ng-template>

    </ngx-light-carousel>
    <div *ngIf="!mediterranean">
      No elements
    </div>
  </div>
</section>
<section class="teachers container-fluid" *ngIf="showSlides && appService.mediterraneancomplete">
  <h2 class="heading">Professors & lectures</h2>
  <div class="slider carousel" *ngIf="appService.professors && appService.professors.length >0">
    <ngx-light-carousel [options]="options" [data]="teachers" class="wrapper c-accent" style="display: block;">
      <section ngx-light-carousel-container class="content">
        <article ngx-light-carousel-item *ngFor="let teacher of teachers; let i = index">
          <div class="letter" *ngIf="teacher.id==0">
            <img [src]="'../../assets/img/team/'+teacher.letter+'.svg'">
          </div>
          <app-teacherSlide [profe]="teacher" *ngIf="teacher.id>0"></app-teacherSlide>
        </article>
        <ng-template #infiniteContainer></ng-template>
      </section>
      <ng-template #carouselPrev>
        <div class="click-area">
          <i class="fa fa-angle-left"></i>
        </div>
      </ng-template>
      <ng-template #carouselNext>
        <div class="click-area">
          <i class="fa fa-angle-right"></i>
        </div>
      </ng-template>

    </ngx-light-carousel>
    <div *ngIf="!teachers">
      No elements
    </div>
  </div>

</section>
<section class="subjects container-fluid"
  *ngIf="showSlides && appService.mediterraneancomplete && appService.teacherscomplete">
  <h2 class="heading">Subjects</h2>
  <div class="slider carousel">
    <ngx-light-carousel [options]="options" [data]="subjects" class="wrapper c-accent" style="display: block;">
      <section ngx-light-carousel-container class="content">
        <article ngx-light-carousel-item *ngFor="let subject of subjects; let i = index">
          <div class="letter" *ngIf="subject.id==0">
            <div class="lett">A</div>
          </div>
          <app-SubjectSlide [subject]="subject" *ngIf="subject.id>0"></app-SubjectSlide>
        </article>
        <ng-template #infiniteContainer></ng-template>
      </section>
      <ng-template #carouselPrev>
        <div class="click-area">
          <i class="fa fa-angle-left"></i>
        </div>
      </ng-template>
      <ng-template #carouselNext>
        <div class="click-area">
          <i class="fa fa-angle-right"></i>
        </div>
      </ng-template>

    </ngx-light-carousel>
    <div *ngIf="!subjects">
      No teachers
    </div>
  </div>
</section>
<section class="workplay container-fluid"
  *ngIf="showSlides && appService.mediterraneancomplete && appService.teacherscomplete">
  <h2 class=" heading">Work & Play</h2>
  <div class="slider carousel">
    <ngx-light-carousel [options]="options" [data]="workplay" class="wrapper c-accent" style="display: block;">
      <section ngx-light-carousel-container class="content">
        <article ngx-light-carousel-item *ngFor="let work of workplay; let i = index">
          <div class="letter" *ngIf="work.id==0">
            <div class="lett">M</div>
          </div>
          <app-workplay style="width: 100%;" *ngIf="work.id>0" [work]="work"></app-workplay>
        </article>
        <ng-template #infiniteContainer></ng-template>
      </section>
      <ng-template #carouselPrev>
        <div class="click-area">
          <i class="fa fa-angle-left"></i>
        </div>
      </ng-template>
      <ng-template #carouselNext>
        <div class="click-area">
          <i class="fa fa-angle-right"></i>
        </div>
      </ng-template>

    </ngx-light-carousel>
    <div *ngIf="!workplay">
      No elements
    </div>
  </div>
</section>


<ng-template #contentlogin let-modallogin>
  <div class="modal-body vimeo">
    <button type="button" class="close" aria-label="Close" (click)="modallogin.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="info-medit">
      <div class="row justify-content-center">
        <div class="col-12 col-md-11">
          <h3>Login</h3>
          <form (ngSubmit)="loginUser()" *ngIf="!registerForm">
            <div class="row">
              <div class="col-12 mb-3 errors" *ngIf="errors">
                <p class="alert alert-error">{{errors.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" placeholder="email" name="email" [(ngModel)]="login.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" placeholder="password" name="password"
                  [(ngModel)]="login.password">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Login">
                <span class="btn btn-white-bg" (click)="showRegister()">Register new user</span>
              </div>
            </div>
            {{message_login | json}}
          </form>
          <form (ngSubmit)="userRegister()" *ngIf="registerForm">
            <div class="row">
              <div class="col-12 mb-3 errors" *ngIf="message">
                <p class="alert alert-error" *ngIf="!message.message">{{message}}</p>
                <p class="alert alert-error" *ngIf="message.message">{{message.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" required placeholder="Your email" name="email"
                  [(ngModel)]="register.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" required placeholder="Your password" name="password"
                  [(ngModel)]="register.password">
              </div>
              <div class="col-12">
                <input type="text" class="form-control" required placeholder="Your name" name="name"
                  [(ngModel)]="register.name">
              </div>
              <div class="col-12 col-md-7">
                <input type="text" class="form-control" placeholder="Your lastname" name="lastname"
                  [(ngModel)]="register.lastname">
              </div>
              <div class="col-12 col-md-5">
                <input type="text" class="form-control" placeholder="Your phone" name="phone"
                  [(ngModel)]="register.phone">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Register">
                <span class="btn btn-white-bg" (click)="showLogin()">Return to login</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>