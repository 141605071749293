import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import $ from "jquery";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-half',
  templateUrl: './half.component.html',
  styleUrls: ['./half.component.scss']
})

export class HalfComponent implements OnInit {
  teachers: any = [];
  subjects: any;
  modalteacher: any = {};
  modalsubject: any = {};
  moremore: Boolean = true;
  sem: any;
  cats: any;
  profesConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "nextArrow": '.teachers-next',
    "prevArrow": '.teachers-prev',
    "infinite": true,
    "autoplay": true,
    "responsive": [
      {
        "breakpoint": 768,
        "settings": {
          "slidesToShow": 2
        }
      },
      {
        "breakpoint": 1100,
        "settings": {
          "slidesToShow": 4
        }
      }
    ]
  };
  modallogin: any = {};
  loggg: any;
  errors: any;
  registerForm: Boolean = false;
  message: any;
  message_login: any;
  register = {
    email: null,
    password: null,
    name: null,
    lastname: null,
    phone: null
  };
  login = {
    email: '',
    password: ''
  }
  closeResult = '';
  assigs = [];
  loader = 0;
  constructor(public appService: AppService, private modalService: NgbModal, private router: Router, private titleService: Title) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.cats = sessionStorage.studies_categories.split(',');
    // console.log('cats',this.appService);
    switch (sessionStorage.studies_study) {
      case '1S': this.sem = '1st semester'; break;
      case '2S': this.sem = '2nd semester'; break;
    }
    if (this.appService.userStudy && this.appService.userStudy.study != '') {
      this.subjects = this.appService.subjects;
      console.log('subjects', this.subjects);
      console.log('user', this.appService.userStudy);
      for (var i = 0; i < this.subjects.length; i++) {
        var a = this.appService.assignatures.filter(p => p.assignatura_id == this.appService.subjects[i].assignatura_fk && p.semestre == sessionStorage.studies_study.charAt(0))[0];
        if (a && a.categories) {
          console.log('cat', a.categories);
          var cats: any;
          if (isNaN(a.categories)) {
            cats = a.categories.split(',').map(Number);
          } else {
            cats = [a.categories];
          }

          for (var c = 0; c < this.appService.userStudy.categories.length; c++) {
            if (cats.includes(parseInt(this.appService.userStudy.categories[c]))) {
              if (this.assigs.filter(j => j.id == this.appService.subjects[i].id).length == 0) {
                this.assigs.push(this.appService.subjects[i]);
              }
            }
          }
        }
        //assignatura té professors
        if (a && a.professors) {
          var teach: any;
          if (isNaN(a.professors)) {
            teach = a.professors.split(',');
          } else {
            teach = [a.professors];
          }
          for (var t = 0; t < teach.length; t++) {
            //comprovem que no estigui a l'array teachers i l'afegim
            if (!this.teachers.some(item => item.teacher_fk == parseInt(teach[t]))) {
              //console.log('aaaaa',this.teachers.some(item => item.id == parseInt(teach[t])));
              if (this.appService.teachers.filter(p => p.teacher_fk == parseInt(teach[t]))[0]) {
                this.teachers.push(this.appService.teachers.filter(p => p.teacher_fk == parseInt(teach[t]))[0]);
              }
              this.loader++;
            }
          }
        }
        if (i == (this.subjects.length - 1)) { this.loader++; }
      }
      console.log('assigs', this.assigs);
      console.log('teachers', this.teachers);
    }
    else {
      this.router.navigateByUrl('/studies');
    }
    console.log('loader', this.loader);
  }
  category(c) {
    var ca = this.appService.categories.filter(a => a.categoria_id == parseInt(c));
    return ca[0].categoria_nom_en;
  }
  semester(num, assig) {
    return this.appService.assignatures.filter(p => p.assignatura_id == assig && p.curs == num)[0];
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  openSubject(contentsubject, subject) {
    console.log('modal slide', subject);
    this.modalsubject.id = subject.id;
    this.modalsubject.photo = subject.photo;
    this.modalsubject.assignatura = this.appService.assignatures.filter(p => p.assignatura_id == subject.assignatura_fk)[0];
    this.modalsubject.categories = [];
    this.moremore = true;
    $('.modal .text').addClass('more');
    //agafem assignatures i fem loop
    if (this.modalsubject.assignatura.categories) {
      var categories = this.modalsubject.assignatura.categories.split(',');
      for (var j = 0; j < categories.length; j++) {
        let cat = this.appService.categories.filter(p => p.categoria_id == categories[j]);
        // console.log('cat',cat);
        if (cat[0].categoria_nom_ca != '') {
          this.modalsubject.categories.push(cat[0]);
        }
      }
    }
    console.log('modalsubject', this.modalsubject);
    this.modalService.open(contentsubject, { size: 'xl', scrollable: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  like(id, table, user, data) {
    if (user.user.id) {
      this.appService.like(id, user.user.id, table, data).subscribe(result => {
        if (result == 200) {
          this.appService.userLikes.push({ 'table_id': id, 'table': table, 'user_id': user.user.id });
        } else {
          console.log('deleted');
          this.appService.userLikes.splice(this.appService.userLikes.findIndex(i => i.table_id == id && i.table == table), 1);
        }
      });
    }
  }
  readMore() {
    $('.modal .text').toggleClass('more');
    this.moremore = !this.moremore;
  }
  showRegister() {
    this.registerForm = true;
  }
  showLogin() {
    this.registerForm = false;
  }
  openlogin(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  userRegister() {
    console.log('form', this.register);
    if (this.register.email && this.register.name && this.register.lastname && this.register.password.length >= 5) {
      this.appService.register(this.register).subscribe(data => {
        console.log('log', data);
        this.message = data;
      });
    } else {
      this.message = 'Complete all fields';
      console.log('error');
    }
  }
  loginUser() {
    this.appService.login(this.login).subscribe(data => {
      console.log('login', data);
      this.appService.updateUser(data);
      this.message_login = data;
      this.modalService.dismissAll();
      this.appService.getUserLikes(this.appService.user.user.id).subscribe(data => {
        console.log('likeeeees', data);
        this.appService.userLikes = data;
      });
    },
      (err) => {
        console.log('err', err);
        this.errors = err;
        this.appService.user.token = null;
        this.appService.user.user = null;
      }
    );
  }

  checkCat(id) {
    return this.appService.category.find(
      c => c.category_fk == id
    );
  }
}
