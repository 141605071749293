<section class="audg" *ngIf="page">
    <div class="container">
        <h1>{{page.title}}</h1>
        <span class="underline u2x"></span>
        <div class="row">
            <div class="col-12 col-md-6">
                <h2>{{page.sections[0].title}}</h2>
                <p class="descr">{{page.sections[0].description}}</p>
                <img src="../../../assets/logo/udg_white.svg" width="150" alt="Logo UdG">
            </div>
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-12 col-md-6" *ngFor="let i of page.sections[0].items; let index = index">
                        <h3><span>{{i.number}}</span></h3>
                        <h4>{{i.title}}</h4>
                        <p>{{i.desc}}</p>
                    </div>
                </div>
            </div>
        </div>
        <h2>Map</h2>
        <span class="underline"></span>
        <agm-map [latitude]="lat" [longitude]="lng" [styles]="styles" [zoom]="zoom" [disableDefaultUI]="true">
            <agm-marker class="agm-marker" animation="drop" [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'EU ERAM'}" [iconUrl]="{url:'../../assets/points/red.png',labelOrigin:{x:12,y:-10}}" latitude="41.9772677" longitude="2.7953343">
            </agm-marker>
            <agm-marker class="agm-marker" animation="drop" [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Campus Barri Vell'}" [iconUrl]="{url:'../../assets/points/blue.png',labelOrigin:{x:12,y:-10}}" latitude="41.9853488" longitude="2.8258162">
                <!-- <agm-info-window>
                    <strong>Sea</strong>
                  </agm-info-window> -->
            </agm-marker>
            <agm-marker class="agm-marker" animation="drop" [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Campus Centre'}" [iconUrl]="{url:'../../assets/points/orange.png',labelOrigin:{x:12,y:-10}}" latitude="41.9745375" longitude="2.8215139">
            </agm-marker>
            <agm-marker class="agm-marker" animation="drop" [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Campus Montilivi'}" [iconUrl]="{url:'../../assets/points/orange.png',labelOrigin:{x:12,y:-10}}" latitude="41.9611942" longitude="2.8243904">
            </agm-marker>
            <agm-marker class="agm-marker" animation="drop" [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Parc Científic'}" [iconUrl]="{url:'../../assets/points/white.png',labelOrigin:{x:12,y:-10}}" latitude="41.9673844" longitude="2.8349512"></agm-marker>
            <agm-marker class="agm-marker" animation="drop" [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'EUSES'}" [iconUrl]="{url:'../../assets/points/white.png',labelOrigin:{x:12,y:-10}}" latitude="41.9691369" longitude="2.7911044">
            </agm-marker>
        </agm-map>
        <span class="underline mt-5"></span>
        <div class="row statics">
            <div class="item col-12" [ngClass]="even ? 'col-md-8':'col-md-4'" *ngFor="let i of page.sections[2].items;let index = index; let even = even; let odd = odd">
                <h3>{{i.number}}</h3>
                <p>{{i.title}}</p>
            </div>
        </div>
        <span class="underline mt-5"></span>
        <div class="history">
            <div class="row">
                <div class="col-12 col-md-8 mb-4"><h2>History of <br> the UdG</h2></div>
                <div class="col-12 col-md-4 mb-4 text-right">
                    <img src="../../../assets/img/udg_history.svg" width="190">
                </div>
                <div class="col-12 col-md-4">
                    <p>{{page.sections[3].column1}}</p>
                </div>
                <div class="col-12 col-md-4">
                    <p>{{page.sections[3].column2}}</p>
                </div>
                <div class="col-12 col-md-4">
                    <p>{{page.sections[3].column3}}</p>
                </div>
            </div>
            <div class="gallery row" *ngIf="page.sections[3].gallery">
                <div class="col-6 col-md-4" *ngFor="let img of page.sections[3].gallery">
                    <img [src]="'https://images.eram.cat/slir/w400-c4x3/international/'+img" alt="UdG">
                </div>
            </div>
        </div>
        <div class="networks">
            <span class="underline"></span>
            <div class="row">
                <div class="col-12 col-md-4">
                    <h2>{{page.sections[4].title}}</h2>
                    <h4>{{page.sections[4].subtitle}}</h4>
                    <img src="../../../assets/img/udg_networks.svg" width="190">
                </div>
                <div class="col-12 col-md-1"></div>
                <div class="col-12 col-md-7">
                    <div class="" [innerHTML]="page.sections[4].description"></div>
                </div>
            </div>
        </div>
        <div class="statics mt-5 w800">
            <div class="row">
                <div class="item col-12 col-md-4" *ngFor="let i of page.sections[4].items">
                    <h3>{{i.number}}</h3>
                    <p>{{i.title}}</p>
                </div>
            </div>
        </div>
        <span class="underline mt-5"></span>
        <div class="university row">
            <div class="col-12 col-md-7">
                <h2>{{page.sections[5].title}}</h2>
                <div class="" [innerHTML]="page.sections[5].description"></div>
                <!-- <p>10th in Research, Chemistry and Industrial Engineering are the 3rd and 4th place respectively.</p>
                <p>Awards InventEUrs__ Best 2019 Education Practices in Europe.</p>
                <p>Global Forum on Human Settlements, an organization linked to UN. Awards in Global Low-Carbon Ecologicla Scenic.</p>
                <p>2nd Catalan University / 9th Spanish University / 47 European University / 105 World University.</p>
                <p>Teaching, research and innovation and technological development, 6th place, and in 5th of the ranking regarding Teaching performance (national Ranking).</p> -->
                <div class="logos">
                    <img src="../../../assets/logo/udg_white.svg" alt="Logo UdG white" width="100">
                    <img src="../../../assets/img/aqu.svg" alt="Logo AQU" width="100">
                    <img src="../../../assets/img/eu.svg" alt="Logo EU " width="100">
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="statics">
                    <div class="item" *ngFor="let i of page.sections[5].items">
                        <h3>{{i.number}}</h3>
                        <p>{{i.title}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>