import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { AppService } from '../app.service';

@Component({
  selector: 'app-iconCategory',
  templateUrl: './IconCategory.component.html',
  styleUrls: ['./IconCategory.component.scss']
})
export class IconCategoryComponent implements OnInit {
  @Input() cat: any;
  icon: any;

  constructor(
    private route: ActivatedRoute,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    //console.log('input',this.cat);
    //console.log('service ',this.appService.category);
    this.icon = this.appService.category.filter(p => p.category_fk == this.cat.categoria_id);
    this.icon = this.icon[0];
    // console.log('hs',this.icon);
  }
}
