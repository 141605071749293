<section class="location">
    <div class="container">
        <h1>Location</h1>
        <span class="underline u2x"></span>
        <h2>Map</h2>
        <span class="underline"></span>
        <div class="row">
            <div class="col-12 col-md-8">
                <agm-map [latitude]="lat" [longitude]="lng" [styles]="styles" [zoom]="zoom" [disableDefaultUI]="true">
                    <agm-marker class="agm-marker" animation="drop"
                        [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'ERAM'}"
                        [iconUrl]="{url:'../../assets/points/red.png',labelOrigin:{x:12,y:-10}}" latitude="41.9772677"
                        longitude="2.7953343">
                    </agm-marker>
                    <agm-marker class="agm-marker" animation="drop"
                        [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Pyrenees'}"
                        [iconUrl]="{url:'../../assets/points/white.png',labelOrigin:{x:12,y:-10}}"
                        latitude="42.33726157" longitude="2.32347965">
                    </agm-marker>
                    <agm-marker class="agm-marker" animation="drop"
                        [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Barcelona'}"
                        [iconUrl]="{url:'../../assets/points/orange.png',labelOrigin:{x:12,y:-10}}"
                        latitude="41.3947847" longitude="2.1136628">
                    </agm-marker>
                    <!-- <agm-marker class="agm-marker" animation="drop" [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Tarragona'}" [iconUrl]="{url:'../../assets/points/white.png',labelOrigin:{x:12,y:-10}}" latitude="41.1258035" longitude="1.221031">
                    </agm-marker> -->
                    <agm-marker class="agm-marker" animation="drop"
                        [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Mediterranean Sea'}"
                        [iconUrl]="{url:'../../assets/points/blue.png',labelOrigin:{x:12,y:-10}}" latitude="41.370416"
                        longitude="2.97424474">
                    </agm-marker>
                </agm-map>
            </div>
            <div class="col-12 col-md-4">
                <div class="distancies">
                    <div class="distancia">
                        <h3 class="time">30'</h3>
                        <div class="dist">
                            <span class="icon ired"></span> <span class="text">Eram</span> <span class="arrow"><img
                                    src="../../../assets/arrow1.svg" style="width: 12%;" alt="arrow"></span><span
                                class="text">Sea</span><span class="icon iblue"></span>
                        </div>
                    </div>
                    <div class="distancia">
                        <h3 class="time">45'</h3>
                        <div class="dist">
                            <span class="icon ired"></span> <span class="text">Eram</span> <span class="arrow"><img
                                    src="../../../assets/arrow2.svg" style="width: 20%;" alt="arrow"></span><span
                                class="text">Pyrenees</span><span class="icon isky"></span>
                        </div>
                    </div>
                    <div class="distancia">
                        <h3 class="time">60'</h3>
                        <div class="dist">
                            <span class="icon ired"></span> <span class="text">Eram</span> <span class="arrow"><img
                                    src="../../../assets/arrow3.svg" style="width: 30%;" alt="arrow"></span><span
                                class="text">Barcelona</span><span class="icon iorange"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mediterranea">
            <h3>Mediterranean experience</h3>
            <span class="underline"></span>
            <div class="grid row">
                <div class="med col-6 col-md-3" *ngFor="let m of appService.mediterranean">
                    <app-mediterranean *ngIf="m.id>0" [medit]="m"></app-mediterranean>
                </div>
            </div>
        </div>
    </div>
</section>