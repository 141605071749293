import { Component } from '@angular/core';
import { AppService } from './app.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Angulartics2GoogleAnalytics } from 'angulartics2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'International - EU ERAM';
  loading = 0;
  form = {
    email: '',
    name: '',
    country: '',
    message: '',
    phone: ''
  };
  showForm = true;
  show = false;
  angForm: FormGroup;
  message: any;
  constructor(
    public appService: AppService,
    private fb: FormBuilder,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics
  ) {
    angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit(): void {
    //this.getHero();
    this.appService.setUserStudy();
    this.appService.getProfessors().subscribe(data => {
      //console.log('professors',data);
      this.appService.professors = data;
      this.loading++;
    });
    this.appService.getTeachers().subscribe(data => {
      console.log('teachers', data);
      this.appService.teachers = data;
      this.loading++;
      for (var i = 0; i < this.appService.teachers.length; i++) {
        //console.log(this.appService.teachers[i])
        if (this.appService.teachers[i].active == 0) {
          this.appService.teachers.splice(i, 1);
        }
      }
    });
    this.appService.getMediterranean().subscribe(data => {
      //console.log('mediterranean',data);
      this.appService.mediterranean = data;
      this.loading++;
      for (const [key, value] of Object.entries(data)) {
        if (value.active == 0) {
          this.appService.mediterranean.splice(key, 1);
        } else {
          this.appService.mediterranean[key].vimeoinfo = '';
          if (value.vimeoid) {
            this.appService.getThumb(value.vimeoid).subscribe(datav => {
              this.appService.mediterranean[key].vimeoinfo = datav[0];
            });
          }
        }
      }
    });
    this.appService.getWorkPlay().subscribe(data => {
      console.log('workplay', data);
      this.appService.workplay = data;
      this.loading++;
      for (const [key, value] of Object.entries(data)) {
        if (value.active == 0) {
          this.appService.mediterranean.splice(key, 1);
        } else {
          this.appService.workplay[key].vimeoinfo = '';
          if (value.id > 0 && (value.vimeoid || value.youtube)) {
            this.appService.getThumb(value.vimeoid).subscribe(datav => {
              this.appService.workplay[key].vimeoinfo = datav[0];
            });
          }
        }
      }
    });
    this.appService.getSubjects().subscribe(data => {
      console.log('subjects', data);
      this.appService.subjects = data;
      this.loading++;
    });
    this.appService.getCategories().subscribe(data => {
      //console.log('categories',data);
      this.appService.categories = data;
    });
    this.appService.getAssignatures().subscribe(data => {
      //console.log('assignatures',data);
      this.appService.assignatures = data;
      this.loading++;
    });
    this.appService.getCategory().subscribe(data => {
      //console.log('category',data);
      this.appService.category = data;
      this.loading++;
    });
    this.appService.getWorkshops().subscribe(data => {
      //console.log('ws',data);
      this.appService.workshop = data;
      this.loading++;
    });
    if (sessionStorage.user_token) {
      console.log(sessionStorage.user_token);
      this.appService.user.token = sessionStorage.user_token;
      this.appService.verify().subscribe(
        {
          next: (value) => {
            this.appService.updateUser(value);
            this.appService.getUserLikes(this.appService.user.user.id).subscribe(data => {
              console.log('likeeeees', data);
              this.appService.userLikes = data;
            });
          }
        });
    }
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phone: ['', Validators.required],
      country: ['', Validators.required],
      message: ['', [Validators.minLength(20), Validators.required]]
    });
    console.log(this.loading);
  }
  send() {
    console.log('form', this.angForm.value);
    this.appService.postInterested(this.angForm.value).subscribe(data => {
      console.log('data', data)
      if (data == 200) {
        this.message = 'Data registered!';
        this.showForm = false;
      } else {
        this.message = 'Error registering data';
        this.showForm = true;
      }
    })

  }
}
