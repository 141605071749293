import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-expo-detail',
  templateUrl: './expo-detail.component.html',
  styleUrls: ['./expo-detail.component.scss']
})
export class ExpoDetailComponent implements OnInit {
  item: any;
  routes: any;
  poster: any;
  id: any;
  constructor(public appService: AppService, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
    this.routes = this.route.params.subscribe(params => {
      console.log('params', params);
      this.id = params.id;
    });
    this.titleService.setTitle('Poster International Exposition - ERAM International');
    window.scroll(0, 0);
    this.titleService.setTitle('Poster International Exposition - ERAM International');
    this.appService.getExposition().subscribe(data => {
      console.log('expo', data);
      this.item = data;
    });
  }
  getData(id) {
    console.log(this.item.filter(e => e.id == id)[0]);
    this.poster = this.item.filter(e => e.id == id)[0];
    if (!Array.isArray(this.poster.gallery)) {
      this.poster.gallery = this.poster.gallery != null && this.poster.gallery != '' ? this.poster.gallery.split(',') : [];
    }
    return this.poster;
  }
}
