import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-work-detail',
  templateUrl: './work-detail.component.html',
  styleUrls: ['./work-detail.component.scss']
})
export class WorkDetailComponent implements OnInit {
  page: any;
  routes: any;
  constructor(public appService: AppService, private route: ActivatedRoute, private sanitizer: DomSanitizer, private location: Location) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.routes = this.route.params.subscribe(params => {
      console.log('params', params);
      this.page = this.appService.workplay.filter(p => p.id == params.id)[0];
    });

    console.log('app', this.page);
  }
  backClicked() {
    this.location.back();
  }

}
