import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppService } from '../../app.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import $ from "jquery";
import { textSpanIntersectsWithPosition } from 'typescript';

@Component({
  selector: 'app-mediterranean',
  templateUrl: './mediterranean.component.html',
  styleUrls: ['./mediterranean.component.scss']
})
export class MediterraneanComponent implements OnInit {

  constructor(public appService: AppService, private modalService: NgbModal, private sanitizer: DomSanitizer, private activeModal: NgbActiveModal) { }

  @Input() medit: any;
  mediterranean: any;
  modalslide: any;
  closeResult = '';
  moremore: any;
  errors: any;
  registerForm: Boolean = false;
  message: any;
  message_login: any;
  register = {
    email: null,
    password: null,
    name: null,
    lastname: null,
    phone: null
  };
  login = {
    email: '',
    password: ''
  }

  ngOnInit(): void {
    //console.log(this.appService);
    //console.log('m',this.medit);
    if (this.medit.table) {
      this.mediterranean = this.appService.mediterranean.filter(m => m.id == this.medit.table_id)[0];
    } else {
      this.mediterranean = this.medit;
    }
    //console.log(this.mediterranean);
  }
  loaded() {
    this.appService.mediterraneanloaded++;
    var medits = this.appService.mediterranean.filter(m => m.active == 1);
    // console.log('med', medits.length);
    // console.log('loaded', this.appService.mediterraneanloaded);
    this.appService.mediterraneancomplete = (this.appService.mediterraneanloaded) >= (medits.length - 1) ? true : false;
  }
  checkLike(table, id) {
    if (this.appService.user.token && this.appService.userLikes) {
      var ex = this.appService.userLikes.filter(like => like.table_id == id && like.table == table);
      if (ex.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  open(content, slide) {
    console.log('modal slide', slide);
    this.modalslide = slide;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.modalslide = {};
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.modalslide = {};
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
