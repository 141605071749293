import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user:any;

  constructor( public appService: AppService ) { }

  ngOnInit(): void {
    console.log('usuari',this.appService.user);
    this.user = this.appService.getUserInfo(this.appService.user.user.id).subscribe( data => {
      console.log(this.user);
    });
  }

}
