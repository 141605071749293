import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../app.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.css']
})
export class LikeComponent implements OnInit {

  constructor(public appService: AppService, private modalService: NgbModal, private activeModal: NgbActiveModal) { }
  @Input() like: any;
  @Input() table: any;
  errors: any;
  registerForm: Boolean = false;
  message: any;
  message_login: any;
  register = {
    email: null,
    password: null,
    name: null,
    lastname: null,
    phone: null
  };
  login = {
    email: '',
    password: ''
  }
  closeResult = '';

  ngOnInit(): void {
    console.log('like', this.like);
    if (this.table == 'study') {
      this.like.id = null;
      this.like.data = { study: this.like.study, cat: this.like.categories.join() };
    } else {
      this.like.data = null;
    }
  }
  checkLike(table, id, data) {
    //console.log('data',data);
    if (this.appService.user.token) {
      var ex = this.appService.userLikes.filter(like => like.table_id == id && like.table == table);
      if (ex.length > 0) {
        console.log('ex', ex);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  likes(id, table, user, data) {
    console.log('like ids', data);
    console.log('like user', user);
    if (user.user.id) {
      this.appService.like(id, user.user.id, table, JSON.stringify(data)).subscribe(result => {
        //console.log('result',result);
        if (result == 200) {
          this.appService.userLikes.push({ 'table_id': id, 'table': table, 'user_id': user.user.id, 'data': data });
        } else {
          console.log('deleted');
          this.appService.userLikes.splice(this.appService.userLikes.findIndex(i => i.table_id == id && i.table == table), 1);
        }
      });
    }
  }
  userRegister() {
    console.log('form', this.register);
    if (this.register.email && this.register.name && this.register.lastname && this.register.password.length >= 5) {
      this.appService.register(this.register).subscribe(data => {
        //console.log('log',data);
        this.message = data;
      });
    } else {
      this.message = 'Complete all fields';
      console.log('error');
    }
  }
  loginUser() {
    this.appService.login(this.login).subscribe(data => {
      console.log('login', data);
      this.appService.updateUser(data);
      this.message_login = data;
      this.modalService.dismissAll();
      this.appService.getUserLikes(this.appService.user.user.id).subscribe(data => {
        console.log('likeeeees', data);
        this.appService.userLikes = data;
      });
    },
      (err) => {
        console.log('err', err);
        this.errors = err;
        this.appService.user.token = null;
        this.appService.user.user = null;
      }
    );
  }
  showRegister() {
    this.registerForm = true;
  }
  showLogin() {
    this.registerForm = false;
  } private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  openlogin(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
