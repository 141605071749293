<section class="workshop">
    <div class="container heading">
        <h1>Workshops</h1>
    </div>
    <div class="container-fluid" *ngIf="workshops">
        <div class="container">
            <h3>Autumn / Winter</h3>
        </div>
        <div class="row" *ngIf="workshops1">
            <div class="ws col-6 col-md-3" *ngFor="let w1 of workshops1">
                <div class="content">
                    <div class="foto">
                        <img src="https://images.eram.cat/slir/w500-h300/curs/{{w1.foto_thumb}}" alt="{{w1.name.en}}">
                    </div>
                    <div class="info">
                        <div class="row align-items-center">
                            <div class="col-10">
                                <p>{{w1.name.en}}</p>
                            </div>
                            <div class="col-2 text-right"><i class="fa fa-heart-o"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <h3>Spring / Summer</h3>
        </div>
        <div class="row" *ngIf="workshops2">
            <div class="ws col-6 col-md-3" *ngFor="let w2 of workshops2">
                <div class="content">
                    <div class="foto">
                        <img src="https://images.eram.cat/slir/w500-h300/curs/{{w2.foto_thumb}}" alt="{{w2.name.en}}"
                            *ngIf="w2.foto_thumb">
                        <img src="https://images.eram.cat/slir/w500-h300/curs/{{w2.foto_generic}}" alt="{{w2.name.en}}"
                            *ngIf="!w2.foto_thumb && w2.foto_generic">
                    </div>
                    <div class="info">
                        <div class="row align-items-center">
                            <div class="col-10">
                                <p>{{w2.name.en}}</p>
                            </div>
                            <div class="col-2 text-right"><i class="fa fa-heart-o"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>