<p>contact works!</p>
{{appService.user}}
<form (ngSubmit)="send()">

    <input type="email" name="email" [(ngModel)]="form.email">
    <input type="password" name="password" [(ngModel)]="form.password">
    <input type="text" name="name" [(ngModel)]="form.name">
    <input type="text" name="lastname" [(ngModel)]="form.lastname">
    <input type="submit">
    <!-- <p style="color:white">{{form | json}}</p>
    <p style="color:white">{{message | json}}</p> -->
</form>

<form (ngSubmit)="loginUser()">
    <input type="email" name="email" [(ngModel)]="login.email">
    <input type="password" name="password" [(ngModel)]="login.password">
    <input type="submit">
    <!-- <p style="color:white">{{message_login | json}}</p>
    <p style="color:white">{{errors | json}}</p> -->
</form>