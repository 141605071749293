import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppService } from '../app.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  team: any;
  modalteacher: any = {};
  moremore: Boolean = true;
  closeResult = '';

  constructor(public appService: AppService, private modalService: NgbModal, private sanitizer: DomSanitizer, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Our Team - ERAM International');
    this.getTeam();
  }

  getTeam(): void {
    this.appService.getTeachers().subscribe(data => {
      this.team = data;
      this.team.splice(1, 0, { 'id': 0, 'letter': 'T' });
      this.team.splice(6, 0, { 'id': 0, 'letter': 'E' });
      this.team.splice(9, 0, { 'id': 0, 'letter': 'A' });
      this.team.splice(15, 0, { 'id': 0, 'letter': 'M' });
      console.log('team', data);
      window.scroll(0, 0);
    });

  }
}
