<div class="slide-subject" (click)="openSubject(contentsubject, subject)" *ngIf="assignatura && subject">
  <div class="foto" *ngIf="assignatura.photo || subject.photo">
    <img src="https://images.eram.cat/slir/w450-c1x1-p1/assignatura/{{assignatura.photo[0].url}}" loading="lazy"
      *ngIf="!subject.photo && assignatura.photo[0]">
  </div>
  <div class="peu">
    <div class="row align-items-center">
      <div class="col-10">
        <p><b>{{assignatura.nom.en}}</b></p>
        <p>{{assignatura.credits}} ECTS</p>
      </div>
      <div class="col-2 text-right"><i class="fa fa-heart-o"></i></div>
    </div>
    <!-- <span class="more">+</span> -->
  </div>
</div>

<ng-template #contentteacher let-modal>
  <div class="modal-teacher">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-md-6 info">
          <!-- <div class="categories">
              <span><i class="fa fa-user"></i></span>
              <span><i class="fa fa-user"></i></span>
              <span><i class="fa fa-user"></i></span>
              <small>(no dinàmic)</small>
            </div> -->
          <h3>{{modalteacher.professor.nom}} {{modalteacher.professor.cog1}} {{modalteacher.professor.cog2}}</h3>
          <h4 class="frase" *ngIf="modalteacher.slogan">{{modalteacher.slogan}}</h4>
          <div class="text" [innerHTML]="modalteacher.professor.curriculum.en"></div>
          <!-- <div class="read-more text-right" *ngIf="modalteacher.professor.curriculum.en.length > 650"
            (click)="readMore()">
            <span class="moremore" *ngIf="moremore">+</span>
            <span class="moreless" *ngIf="!moremore">-</span>
          </div> -->
          <div class="subjects text-left" *ngIf="modalteacher.professor.subjects">
            <h4>Subjects</h4>
            <p *ngIf="modalteacher.assignatures.length>0">
              <span *ngFor="let a of modalteacher.assignatures">
                <ng-container *ngIf="a">
                  <span *ngIf="a.title.en"><a
                      href="https://www.eram.cat/en/studies/degree-in-audiovisual-communication-and-multimedia/study-plan-gcam/{{a.mr.en}}"
                      target="_blank">{{a.title.en}}</a></span>
                  <span *ngIf="!a.title.en"><a
                      href="https://www.eram.cat/ca/estudis/grau-en-comunicacio-audiovisual-i-multimedia/pla-destudis-gcam/{{a.mr.ca}}"
                      target="_blank">{{a.title.ca}}</a></span>
                </ng-container>
              </span>
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 text-right">
          <div class="foto">
            <img src="https://images.eram.cat/slir/w650/persona/{{modalteacher.photo}}" *ngIf="modalteacher.photo">
            <img src="https://images.eram.cat/slir/w650/persona/{{modalteacher.professor.foto.url}}"
              *ngIf="!modalteacher.photo && modalteacher.professor.foto.url">
          </div>
        </div>
        <div class="col-12">
          <form>
            <div class="row align-items-center">
              <div class="col-12 col-md-3">
                <h5>What's your favourite place?</h5>
              </div>
              <div class="col-12 col-md-6">
                <input type="text" class="form-control" placeholder="Type place">
              </div>
              <div class="col-12 col-md-3">
                <input type="submit">
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 related">
          <h4>Related</h4>
          fdsfdsaf
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #contentsubject let-modal>
  <div class="modal-subject">
    <div class="modal-header"
      style="background-image: url(https://images.eram.cat/slir/w1200-q100-p1/assignatura/{{modalsubject.assignatura.photo[0].url}});">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 title">
          <div class="categories">
            <div class="cat" *ngFor="let i of modalsubject.categories">
              <app-iconCategory [cat]="i" *ngIf="checkCat(i.categoria_id)"></app-iconCategory>
            </div>
          </div>
          <h3>{{modalsubject.assignatura.nom.en}}</h3>
          <p class="dades">
            <span class="ects">{{modalsubject.assignatura.credits}}ECTS</span>
            <span class="course">{{modalsubject.assignatura.curs}}<ng-container
                *ngIf="modalsubject.assignatura.curs==1">st</ng-container>
              <ng-container *ngIf="modalsubject.assignatura.curs==2">nd</ng-container>
              <ng-container *ngIf="modalsubject.assignatura.curs==3">rd</ng-container>
              <ng-container *ngIf="modalsubject.assignatura.curs>3">th</ng-container>
              course
            </span>
            <span class="semestre" *ngIf="modalsubject.assignatura.durada=='S'">{{modalsubject.assignatura.semestre}}
              semester</span>
            <span class="semestre" *ngIf="modalsubject.assignatura.durada=='A'">Anual </span>
          </p>
        </div>
        <div class="col-12 col-md-5 info">
          <div class="tags" *ngIf="modalsubject.categories">
            <span *ngFor="let c of modalsubject.categories">#{{c.categoria_nom_en}}</span>
          </div>
          <div class="type" *ngIf="modalsubject.skills">
            <h4>Skills</h4>
            <p>{{modalsubject.skills}}</p>
          </div>
          <div class="type" *ngIf="modalsubject.career">
            <h4>Career opportunities</h4>
            <p>{{modalsubject.career}}</p>
          </div>
        </div>
        <div class="col-12 col-md-7 info">
          <div class="descripcio text" *ngIf="modalsubject.assignatura.text.en"
            [innerHTML]="modalsubject.assignatura.text.en"></div>
          <!-- <div class="read-more text-right" *ngIf="modalsubject.assignatura.text.en.length > 650" (click)="readMore()">
            <span class="moremore" *ngIf="moremore">+</span>
            <span class="moreless" *ngIf="!moremore">-</span>
          </div> -->
        </div>
        <div class="col-12 works" *ngIf="modalsubject.assignatura.treballs.length>0">
          <div class="work row">
            <div class="col-6 col-md-3" *ngFor="let w of modalsubject.assignatura.treballs">
              <div class="content">
                <div class="foto">
                  <img [src]="'https://images.eram.cat/slir/w300-c1x1-q100-p1/treball/'+w.treball.foto[0].url">
                </div>
                <div class="peu">
                  <h5>{{w.treball.nom.en}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentlogin let-modallogin>
  <div class="modal-body vimeo">
    <button type="button" class="close" aria-label="Close" (click)="modallogin.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="info-medit">
      <div class="row justify-content-center">
        <div class="col-12 col-md-11">
          <h3>Login</h3>
          <form (ngSubmit)="loginUser()" *ngIf="!registerForm">
            <div class="row">
              <div class="col-12 mb-3 errors" *ngIf="errors">
                <p class="alert alert-error">{{errors.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" placeholder="email" name="email" [(ngModel)]="login.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" placeholder="password" name="password"
                  [(ngModel)]="login.password">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Login">
                <span class="btn btn-white-bg" (click)="showRegister()">Register new user</span>
              </div>
            </div>
            {{message_login | json}}
          </form>
          <form (ngSubmit)="userRegister()" *ngIf="registerForm">
            <div class="row">
              <div class="col-12 mb-3 errors" *ngIf="message">
                <p class="alert alert-error" *ngIf="!message.message">{{message}}</p>
                <p class="alert alert-error" *ngIf="message.message">{{message.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" required placeholder="Your email" name="email"
                  [(ngModel)]="register.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" required placeholder="Your password" name="password"
                  [(ngModel)]="register.password">
              </div>
              <div class="col-12">
                <input type="text" class="form-control" required placeholder="Your name" name="name"
                  [(ngModel)]="register.name">
              </div>
              <div class="col-12 col-md-7">
                <input type="text" class="form-control" placeholder="Your lastname" name="lastname"
                  [(ngModel)]="register.lastname">
              </div>
              <div class="col-12 col-md-5">
                <input type="text" class="form-control" placeholder="Your phone" name="phone"
                  [(ngModel)]="register.phone">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Register">
                <span class="btn btn-white-bg" (click)="showLogin()">Return to login</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>