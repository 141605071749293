<section class="user">
    <div class="container">
        <h1 class="title">my user</h1>
        <div class="row">
            <div class="col-12 col-md-4">
                <h2>Welcome,<br>{{appService.user.user.email | json}}</h2>
            </div>
            <div class="col-12 col-md-8">
                <h3>personal profile</h3>
            </div>
        </div>
    </div>
</section>
