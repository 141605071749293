<section class="team-detail">
    <div class="container">
        <div class="row" *ngIf="team && appService.professors">
            <div class="col-6 col-sm-4 col-md-3 team-item" *ngFor="let t of team">
                <app-teacherSlideInfo [profe]="t" *ngIf="t.id>0"></app-teacherSlideInfo>
                <div class="letter" *ngIf="t.id==0">
                    <img [src]="'../../assets/img/team/'+t.letter+'.svg'">
                </div>
            </div>
        </div>
    </div>
</section>