<section class="study">
    <div class="container">
        <h2>Make your choice! </h2>
        <div class="row map align-items-center justify-content-center">
            <!-- <div class="col-12 col-md-3 studies">
                <div class="selection" (click)="selectType('WS')">
                    <span id="WS" class="circle"></span>
                    <h4>Workshop</h4>
                    <p>Summer - Winter</p>
                </div>
            </div> -->
            <div class="col-12 col-md-3 studies">
                <div class="row">
                    <div class="col-6 col-md-12 selection mb-5" (click)="selectType('1S')">
                        <span id="1S" class="circle"></span>
                        <h4>6 months</h4>
                        <p>Autumn / Winter</p>
                    </div>
                    <div class="col-6 col-md-12 selection" (click)="selectType('2S')">
                        <span id="2S" class="circle"></span>
                        <h4>6 months</h4>
                        <p>Spring / Summer</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 studies">
                <div class="selection" (click)="selectType('1Y')">
                    <span id="1Y" class="circle"></span>
                    <h4>1 year</h4>
                </div>
            </div>
            <div class="col-6 col-md-3 studies">
                <div class="selection" (click)="selectType('D')">
                    <span id="D" class="circle"></span>
                    <h4>Bachelor</h4>
                </div>
            </div>
        </div>
        <div class="course-type">
            <h4 class="my-4">Choose your itinerary <small style="color:rgba(255,255,255,.5)">(you can select more than
                    one)</small> </h4>
            <div class="row justify-content-center mb-5" *ngIf="cats">
                <div class="col5 col-6 itinerary-selection" *ngFor="let c of cats">
                    <div class="content" id="cat-{{c.category_fk}}" (click)="selectCat(c.category_fk)">
                        <p class="blanc"><b>{{catname(c.category_fk)}}</b></p>
                        <img class="mt-3" *ngIf="c.icon"
                            [src]="'https://images.eram.cat/slir/w160-c1x1/categoria/'+c.icon">
                    </div>
                </div>
            </div>
        </div>
        <h4 class="my-5 letsgo" (click)="goToResult()" *ngIf="disabled"> Let's go -></h4>
        <h4 class="my-5 letsgo disabled" *ngIf="!disabled"> Let's go </h4>
    </div>
</section>