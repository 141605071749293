import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  email = '';
  message: any;
  message_login: any;
  form = {
    email: '',
    password: '',
    name: '',
    lastname: '',
    phone: ''
  };
  login = {
    email: '',
    password: ''
  }
  errors: any;
  constructor(public appService: AppService, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Contact - ERAM International');
  }
  send() {
    console.log('form', this.form);
    this.appService.register(this.form).subscribe(data => {
      console.log('log', data);
      this.message = data;
    });
  }
  loginUser() {
    console.log(this.login);
    this.appService.login(this.login).subscribe(data => {
      console.log('log', data);
      this.message_login = data;
      this.appService.updateUser(data);
      sessionStorage.setItem('user_token', this.appService.user.token);
      sessionStorage.setItem('user_id', this.appService.user.user.id);
    },
      (err) => {
        console.log(err);
        this.errors = err;
        this.appService.user.token = null;
        this.appService.user.user = null;
      }
    );
  }
}
