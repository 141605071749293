<section class="exposition">
   <div class="container-fluid heading">
      <img src="../../assets/img/header_exposition.jpg" alt="header exposition">
   </div>
   <div class="container" *ngIf="expo">
      <div class="contingut">
         <div class="row">
            <div class="col-6 col-sm-4 col-lg-3 expoitem" *ngFor="let e of expo">
               <div class="element" *ngIf="e.id >0">
                  <a [routerLink]="['/workplay/exposition/'+e.id]">
                     <img [src]="'https://images.eram.cat/slir/w300-c1x1-p1-q100/exposition/'+e.profile"
                        *ngIf="!e.postersmall">
                     <img [src]="'https://images.eram.cat/slir/w300-c1x1-p1-q100/exposition/'+e.postersmall"
                        *ngIf="e.postersmall">
                  </a>
               </div>
               <div class="element" *ngIf="e.id == 0 && !e.letter">
                  <div class="square-color" [ngStyle]="{'background-color': e.color}"></div>
               </div>
               <div class="element" *ngIf="e.id == 0 && e.letter">
                  <img src="./assets/img/letters/{{e.letter}}.png">
               </div>
            </div>
         </div>
      </div>
   </div>
</section>