<footer>
    <div class="container menu-footer">
        <div class="row align-items-center">
            <div class="col-12 col-md-7 informacio">
                <img src="../../assets/logo/logonegre.svg">
                <p>Center attached to the University of Girona</p>
                <p>Factoria Cultural Coma Cros <br>C/St.Antoni, 1(Salt) Girona</p>
            </div>
            <div class="col-12 col-md-5 hide-mobile">
                <div class="row">
                    <div class="col-6 col-md-6 ">
                        <ul>
                            <li><a routerLink="/studies"> - Studies</a></li>
                            <li><a routerLink="/team"> - Our Team</a></li>
                            <!-- <li><a href="#"><i class="fa fa-chevron-right"></i> ERAM@Work&Play</a></li> -->
                            <!-- <li><a routerLink="/contact"> -  Contact Us</a></li> -->
                        </ul>
                    </div>
                    <div class="col-6 col-md-6 ">
                        <ul>
                            <li><a routerLink="/about/location"> - Location</a></li>
                            <li><a routerLink="/about/udg"> - About UdG</a></li>
                            <li><a routerLink="/about/eram"> - About ERAM</a></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="container-fluid copy">
        <div class="container align-items-center">
            <div class="row align-items-center justify-content-center">
                <div class="col-12 col-md-6 text-center">
                    <p>©2021 EU ERAM - All rights reserved.</p>
                </div>
                <!-- <div class="col-12 col-md-6 text-right">
                    <ul class="links">
                        <li class="nav-item">
                            <a href="tel:+34972402258">POLÍTICA DE PRIVACITAT</a>
                        </li>
                        <li class="nav-item">
                            <a href="tel:+34972402258">TERMES D'ÚS</a>
                        </li>
                        <li class="nav-item">
                            <a href="tel:+34972402258">FAQ</a>
                        </li>
                        <li class="nav-item">
                            <a href="tel:+34972402258">CONTACTE</a>
                        </li>
                    </ul>
                </div>                     -->
            </div>
        </div>
    </div>
</footer>