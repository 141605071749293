import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppService {
  public professors;
  public teachers;
  public mediterranean;
  public assignatures;
  public workplay;
  public subjects;
  public categories;
  public category;
  public workshop;
  public userStudy;
  public userLikes;
  public user = { "token": null, "user": null };

  public mediterraneanloaded = 0;
  public teachersloaded = 0;
  public teacherscomplete = false;
  public mediterraneancomplete = false;
  //private apiUrl = 'http://localhost:3000';  // URL to web api
  private apiUrl = 'https://api.eram.cat';  // URL to web api

  constructor(
    private http: HttpClient
  ) { }

  getSlides() {
    return this.http.get(this.apiUrl + '/international/slides');
  }
  getMediterranean() {
    return this.http.get(this.apiUrl + '/international/mediterranean');
  }
  getTeachers() {
    return this.http.get(this.apiUrl + '/international/teachers');
  }
  getCategories() {
    return this.http.get(this.apiUrl + '/categoria');
  }
  getProfessors() {
    return this.http.get(this.apiUrl + '/professor');
  }
  getSubjects() {
    return this.http.get(this.apiUrl + '/international/subjects');
  }
  getAssignatures() {
    return this.http.get(this.apiUrl + '/assignatures');
  }
  getWorkPlay() {
    return this.http.get(this.apiUrl + '/international/workplay');
  }
  getExposition() {
    return this.http.get(this.apiUrl + '/international/exposition');
  }
  getCategory() {
    return this.http.get(this.apiUrl + '/international/categories');
  }
  getThumb(id) {
    //console.log(id);
    if (id != null) {
      return this.http.get('https://vimeo.com/api/v2/video/' + id + '.json');
    } else {
      return null;
    }
  }
  getTeacherWorks(teacher) {
    return this.http.get(this.apiUrl + '/professor/works/' + teacher);
  }
  getTeachersResearch(teacher) {
    return this.http.get(this.apiUrl + '/international/teachers/research/' + teacher);
  }
  getTeachersNews(teacher) {
    return this.http.get(this.apiUrl + '/international/teachers/news/' + teacher);
  }
  getPage(id) {
    return this.http.get(this.apiUrl + '/international/page/' + id);
  }
  getWorkshops() {
    return this.http.get(this.apiUrl + '/workshop');
  }
  setUserStudy() {
    this.userStudy = {
      study: sessionStorage.studies_study ? sessionStorage.studies_study : '',
      categories: sessionStorage.studies_categories ? sessionStorage.studies_categories.split(',') : []
    }
  }
  login(params) {
    return this.http.post(this.apiUrl + '/international/user/login', params).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
  register(params) {
    return this.http.post(this.apiUrl + '/international/user/register', params);
  }
  verify() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.get(this.apiUrl + '/international/user/verify', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.user.token }) });
  }
  getUserLikes(user) {
    return this.http.get(this.apiUrl + '/international/like/' + user, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.user.token }) });
  }
  handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = { "error_message": error.error.message };
    } else {
      // server-side error
      errorMessage = { "error_code": error.status, "message": error.error.message, "error_message": error.message };
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
    //return throwError(error);
  }
  updateUser(data) {
    console.log('updateUser', data);
    this.user.token = data.token;
    this.user.user = data.user;
    sessionStorage.setItem('user_token', this.user.token);
    sessionStorage.setItem('user_id', this.user.user.id);
  }
  getUserInfo(id) {
    return this.http.get(this.apiUrl + '/international/user/' + id, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.user.token }) });
  }
  like(id, user_id, table, data) {
    const dades = { "table_id": id, "user_id": user_id, "table": table, "data": data };
    console.log('dades', dades);
    return this.http.post(this.apiUrl + '/international/like', dades).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
  postFeedback(table, table_fk, answer) {
    const dades = { "table_fk": table_fk, "answer": answer, "table": table };
    return this.http.post(this.apiUrl + '/international/feedback', dades).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
  postInterested(form) {
    console.log('form service', form);
    return this.http.post(this.apiUrl + '/international/interested', form).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
  //Authorization: "Bearer " + token
}
