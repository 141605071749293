<section class="favorites">
    <div class="container">
        <p *ngIf="!favorites">{{message}}</p>
        <h1 class="title" *ngIf="favorites">My favorites</h1>
        <ngx-masonry [ordered]="true" [options]="masonryOptions" class="row" *ngIf="favorites">
            <div ngxMasonryItem class="col-6 col-md-3 element masonry-item" *ngFor="let item of favorites">
                <div masonryLazy loading="lazy">
                    <ng-container masonryLazy loading="lazy" (load)="imageLoad(item)" *ngIf="item.table == 'mediterranean'">
                        <app-mediterranean [medit]="item"></app-mediterranean>
                    </ng-container>
                    <ng-container masonryLazy loading="lazy" (load)="imageLoad(item)" *ngIf="item.table == 'workplay'">
                        <app-workplay [work]="item"></app-workplay>
                    </ng-container>
                    <ng-container masonryLazy loading="lazy"  (load)="imageLoad(item)" *ngIf="item.table == 'teacher'">
                        <app-teacherSlideInfo [profe]="item"></app-teacherSlideInfo>
                    </ng-container>
                    <ng-container masonryLazy loading="lazy"  (load)="imageLoad(item)" *ngIf="item.table == 'study'">
                        <div class="study">
                            <div class="content">
                                <div class="text">
                                    <h4 >{{showStudy(item.data)}}</h4>
                                    <div class="cats" *ngIf="item.data" [innerHtml]="showCats(item.data)"></div>
                                </div>
                                <div class="heart">
                                    <i class="fa fa-heart"></i>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <img (load)="imageLoad(item)" masonryLazy loading="lazy" class="col-6 col-md-3 invisible" src="../../../assets/img/white.png" />
                </div>
            </div>
            
        </ngx-masonry>
    </div>
</section>

