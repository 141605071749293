import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppService } from '../../app.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import $ from "jquery";
import { textSpanIntersectsWithPosition } from 'typescript';

@Component({
  selector: 'app-workplay',
  templateUrl: './workplay.component.html',
  styleUrls: ['./workplay.component.scss']
})
export class WorkplayComponent implements OnInit {

  constructor(public appService: AppService, private modalService: NgbModal, private sanitizer: DomSanitizer, private activeModal: NgbActiveModal) { }

  @Input() work: any;
  workplay: any;
  modalslide: any;
  closeResult = '';
  moremore: any;
  errors: any;
  registerForm: Boolean = false;
  message: any;
  message_login: any;
  register = {
    email: null,
    password: null,
    name: null,
    lastname: null,
    phone: null
  };
  login = {
    email: '',
    password: ''
  }

  ngOnInit(): void {
    //console.log(this.appService);
    //console.log('m',this.work);
    if (this.work.table) {
      this.workplay = this.appService.workplay.filter(m => m.id == this.work.table_id)[0];
    } else {
      this.workplay = this.work;
    }
    //console.log(this.workplay);
  }
  checkLike(table, id) {
    if (this.appService.user.token && this.appService.userLikes) {
      var ex = this.appService.userLikes.filter(like => like.table_id == id && like.table == table);
      if (ex.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  vimeoUrl(id) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + id + '?autoplay=false');
  }
  youtubeUrl(id) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + id + '?autoplay=false');
  }
  open(content, slide) {
    //console.log('modal slide',slide);
    this.modalslide = slide;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.modalslide = {};
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.modalslide = {};
    });
  }
  like(id, table, user, data) {
    if (user.user.id) {
      this.appService.like(id, user.user.id, table, data).subscribe(result => {
        if (result == 200) {
          this.appService.userLikes.push({ 'table_id': id, 'table': table, 'user_id': user.user.id });
        } else {
          console.log('deleted');
          this.appService.userLikes.splice(this.appService.userLikes.findIndex(i => i.table_id == id && i.table == table), 1);
        }
      });
    }
  }
  userRegister() {
    console.log('form', this.register);
    if (this.register.email && this.register.name && this.register.lastname && this.register.password.length >= 5) {
      this.appService.register(this.register).subscribe(data => {
        console.log('log', data);
        this.message = data;
      });
    } else {
      this.message = 'Complete all fields';
      console.log('error');
    }
  }
  loginUser() {
    this.appService.login(this.login).subscribe(data => {
      console.log('login', data);
      this.appService.updateUser(data);
      this.message_login = data;
      this.modalService.dismissAll();
      this.appService.getUserLikes(this.appService.user.user.id).subscribe(data => {
        console.log('likeeeees', data);
        this.appService.userLikes = data;
      });
    },
      (err) => {
        console.log('err', err);
        this.errors = err;
        this.appService.user.token = null;
        this.appService.user.user = null;
      }
    );
  }
  readMore() {
    $('.modal .text').toggleClass('more');
    this.moremore = !this.moremore;
  }
  showRegister() {
    this.registerForm = true;
  }
  showLogin() {
    this.registerForm = false;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  openlogin(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
