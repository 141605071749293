import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from '../app.service';

@Component({
  selector: 'app-SubjectSlide',
  templateUrl: './SubjectSlide.component.html',
  styleUrls: ['./SubjectSlide.component.scss']
})
export class SubjectSlideComponent implements OnInit {
  @Input() subject: any;
  assignatura: any;
  closeResult = '';
  feedback = { answer: null };
  answered: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public appService: AppService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    //console.log('input', this.subject);
    this.assignatura = this.appService.assignatures.filter(p => p.assignatura_id == this.subject.assignatura_fk);
    //console.log('input ass', this.assignatura);
    this.assignatura = this.assignatura[0];
  }
  openSubject(contentsubject, subject) {
    this.subject.id = subject.id;
    this.subject.skills = subject.skills;
    this.subject.career = subject.career;
    this.subject.photo = subject.photo;
    this.subject.assignatura = this.appService.assignatures.filter(p => p.assignatura_id == subject.assignatura_fk)[0];
    this.subject.categories = [];
    //$('.modal .text').addClass('more');
    //agafem assignatures i fem loop
    //console.log('assignatura: ', this.subject.assignatura);
    if (this.subject.assignatura.categories) {
      if (!Number.isInteger(this.subject.assignatura.categories)) {
        var categories = this.subject.assignatura.categories.split(',');
        for (var j = 0; j < categories.length; j++) {
          let cat = this.appService.categories.filter(p => p.categoria_id == categories[j]);
          if (cat[0].categoria_nom_ca != '') {
            this.subject.categories.push(cat[0]);
          }
        }
      } else {
        this.subject.categories.push(this.subject.assignatura.categories);
      }
    }
    //console.log('subject home', this.subject);
    this.modalService.open(contentsubject, { size: 'xl', scrollable: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  checkCats(cats) {
    //console.log('cacaac',cats);
    var result = 0;
    for (var i = 0; i < cats.length; i++) {
      if (cats[i].categoria_nom_en == 'Research') {
        result++;
      }
    }
    return result;
  }
  checkCat(id) {
    return this.appService.category.find(
      c => c.category_fk == id
    );
  }
  checkLike(table, id) {
    if (this.appService.user.token && this.appService.userLikes) {
      var ex = this.appService.userLikes.filter(like => like.table_id == id && like.table == table);
      if (ex.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  postFeedback(table, table_fk, answer) {
    console.log(answer);
    if (answer != null) {
      this.appService.postFeedback(table, table_fk, answer).subscribe(result => {
        console.log(result);
        if (result) {
          this.answered = true;
        }
      });
    } else {
      alert('SELECT AN OPTION PLEASE');
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
