<section class="aeram" *ngIf="page">
    <div class="container">
        <h1>{{page.title}}</h1>
        <span class="underline u2x"></span>
        <div class="row" *ngIf="page.sections[0]">
            <div class="col-12 col-md-4">
                <h2>{{page.sections[0].title}}</h2>
                <p class="descr">{{page.sections[0].description}}</p>
                <span class="underline mt-1"></span>
            </div>
            <div class="col-12 col-md-8">
                <div class="row statics">
                    <div class="item col-6 col-md-{{i.columns}}" *ngFor="let i of page.sections[0].items">
                        <h3>{{i.number}}</h3>
                        <p>{{i.title}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="numbers row mt-4">
            <div class="col-12 col-md-3" *ngFor="let j of page.sections[2].items">
                <h3><span>{{j.number}}</span></h3>
                <h4>{{j.title}}</h4>
                <p>{{j.descr}}</p>
            </div>
        </div>
        <h2>Map</h2>
        <span class="underline"></span>
        <agm-map [latitude]="lat" [longitude]="lng" [styles]="styles" [zoom]="zoom" [disableDefaultUI]="true">
            <agm-marker class="agm-marker" animation="drop"
                [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'EU ERAM'}"
                [iconUrl]="{url:'../../assets/points/red.png',labelOrigin:{x:12,y:-10}}" latitude="41.9772677"
                longitude="2.7953343">
            </agm-marker>
            <agm-marker class="agm-marker" animation="drop"
                [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Campus Barri Vell'}"
                [iconUrl]="{url:'../../assets/points/blue.png',labelOrigin:{x:12,y:-10}}" latitude="41.9853488"
                longitude="2.8258162">
                <!-- <agm-info-window>
                    <strong>Sea</strong>
                  </agm-info-window> -->
            </agm-marker>
            <agm-marker class="agm-marker" animation="drop"
                [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Campus Centre'}"
                [iconUrl]="{url:'../../assets/points/orange.png',labelOrigin:{x:12,y:-10}}" latitude="41.9745375"
                longitude="2.8215139">
            </agm-marker>
            <agm-marker class="agm-marker" animation="drop"
                [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Campus Montilivi'}"
                [iconUrl]="{url:'../../assets/points/orange.png',labelOrigin:{x:12,y:-10}}" latitude="41.9611942"
                longitude="2.8243904">
            </agm-marker>
            <agm-marker class="agm-marker" animation="drop"
                [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'Parc Científic'}"
                [iconUrl]="{url:'../../assets/points/white.png',labelOrigin:{x:12,y:-10}}" latitude="41.9673844"
                longitude="2.8349512"></agm-marker>
            <agm-marker class="agm-marker" animation="drop"
                [label]="{color: 'white',fontFamily: '',fontSize: '13px',fontWeight: 'normal',text: 'EUSES'}"
                [iconUrl]="{url:'../../assets/points/white.png',labelOrigin:{x:12,y:-10}}" latitude="41.9691369"
                longitude="2.7911044">
            </agm-marker>
        </agm-map>
        <h2>Facilities</h2>
        <span class="underline"></span>
        <div class="tour" (click)="open(content)">
            <h3>Tour <span class="red"></span></h3>
        </div>
        <div class="gallery gcam">
            <div class="gal" *ngIf="page.sections[6].gallery">
                <ngx-slick-carousel class="carousel" [config]="gcamConfig">
                    <div ngxSlickItem *ngFor="let g of page.sections[6].gallery" class="">
                        <div class="content">
                            <img [src]="'https://images.eram.cat/slir/w400-c4x3/international/'+g">
                        </div>
                    </div> 
                </ngx-slick-carousel>
                <div class="arrows-prev arrows gcam-prev"><i class="fa fa-angle-left"></i></div>
                <div class="arrows-next arrows gcam-next"><i class="fa fa-angle-right"></i></div>
            </div>
        </div>
        <div class="gallery gcam">
            <div class="gal" *ngIf="page.sections[7].gallery">
                <ngx-slick-carousel class="carousel" [config]="gaeConfig">
                    <div ngxSlickItem *ngFor="let g of page.sections[7].gallery" class="">
                        <div class="content">
                            <img [src]="'https://images.eram.cat/slir/w400-c4x3/international/'+g">
                        </div>
                    </div> 
                </ngx-slick-carousel>
                <div class="arrows-prev arrows gae-prev"><i class="fa fa-angle-left"></i></div>
                <div class="arrows-next arrows gae-next"><i class="fa fa-angle-right"></i></div>
            </div>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Xaf13_scmpU"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen style="width: 100%;height:60vh;aspect-ratio: 16/9;"></iframe>
    </div>
</ng-template>