import { Component, OnInit } from '@angular/core';
//import { Hero } from '../hero';
import { AppService } from '../app.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {
  //heroes: Hero[] = [];
  modallogin:any = {};
  closeResult:any = {};
  email = '';
  message:any;
  message_login:any;
  registerForm:Boolean = false;
  register = {
    email : '',
    password : '',
    name : '',
    lastname : '',
    phone : ''
  };
  login = {
    email:'',
    password : ''
  }
  errors:any;
  open:boolean = false;

  constructor(public appService: AppService, private modalService: NgbModal) { }

  ngOnInit() {
    
  }

  loginUser(){
    console.log(this.login);
    this.appService.login(this.login).subscribe(data => {
      console.log('login',data);
      this.message_login = data;
      this.appService.updateUser(data);
      this.appService.getUserLikes(this.appService.user.user.id).subscribe(data => {
        console.log('likeeeees',data);
        this.appService.userLikes = data;
      });
      this.modalService.dismissAll();
    },
    (err) => {
      console.log('err',err);
      this.errors = err;
      this.appService.user.token = null;
      this.appService.user.user=null;
    }
    );
  }
  toggleClass(){
    this.open = !this.open;
  }
  userRegister(){
    if(this.register.email && this.register.name && this.register.lastname && this.register.password.length>=5){
      this.appService.register(this.register).subscribe(data => {
        console.log('log',data);
        this.message = data;
      });
    }else{
      this.message = 'Complete all fields';
      console.log('error');
    }
  }
  showRegister(){
    this.registerForm = true;
  }
  showLogin(){
    this.registerForm = false;
  }
  openlogin(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size:'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  logout(){
    this.appService.user = null;
    sessionStorage.clear();
  }
}
