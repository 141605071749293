<span class="heart" *ngIf="checkLike(table,like.id,like.data) && this.appService.user.token" (click)="likes(like.id,table,appService.user,like.data)"><i class="fa fa-heart fa-2x"></i></span>
<span class="heart" *ngIf="!checkLike(table,like.id,like.data) && this.appService.user.token" (click)="likes(like.id,table,appService.user,like.data)"><i class="fa fa-heart-o fa-2x"></i></span>
<span class="heart" *ngIf="!this.appService.user.token" (click)="openlogin(contentlogin)"><i class="fa fa-heart-o fa-2x"></i></span>


<ng-template #contentlogin let-modallogin>
    <div class="modal-body vimeo">
      <button type="button" class="close" aria-label="Close" (click)="modallogin.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      
      <div class="info-medit">
        <div class="row justify-content-center">
          <div class="col-12 col-md-11">
            <h3>Login</h3>
            <form (ngSubmit)="loginUser()" *ngIf="!registerForm">
              <div class="row">
                <div class="col-12 mb-3 errors" *ngIf="errors">
                  <p class="alert alert-error">{{errors.message}}</p>
                </div>
                <div class="col-12 col-md-6">
                  <input type="email" class="form-control" placeholder="email" name="email" [(ngModel)]="login.email">
                </div>
                <div class="col-12 col-md-6">
                  <input type="password" class="form-control" placeholder="password" name="password" [(ngModel)]="login.password">
                </div>
                <div class="col-12">
                  <input type="submit" class="btn btn-white" value="Login">
                  <span class="btn btn-white-bg" (click)="showRegister()">Register new user</span>
                </div>
              </div>
              {{message_login | json}}
          </form>
          <form (ngSubmit)="userRegister()" *ngIf="registerForm">
            <div class="row">
              <div class="col-12 mb-3 errors" *ngIf="message">
                <p class="alert alert-error" *ngIf="!message.message">{{message}}</p>
                <p class="alert alert-error" *ngIf="message.message">{{message.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" required placeholder="Your email" name="email" [(ngModel)]="register.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" required placeholder="Your password" name="password" [(ngModel)]="register.password">
              </div>
              <div class="col-12">
                <input type="text" class="form-control" required placeholder="Your name" name="name" [(ngModel)]="register.name">
              </div>
              <div class="col-12 col-md-7">
                <input type="text" class="form-control" placeholder="Your lastname" name="lastname" [(ngModel)]="register.lastname">
              </div>
              <div class="col-12 col-md-5">
                <input type="text" class="form-control" placeholder="Your phone" name="phone" [(ngModel)]="register.phone">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Register">
                <span class="btn btn-white-bg" (click)="showLogin()">Return to login</span>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  </ng-template>