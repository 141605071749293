<section class="degree">
  <div class="container heading">
    <div class="row align-items-center">
      <div class="col-10">
        <h1>6 months</h1>
      </div>
      <div class="col-2 text-right">
        <app-like [like]="appService.userStudy" [table]="'study'"></app-like>
      </div>
    </div>
    <h2><i class="fa fa-clock-o"></i> {{sem}} <span class="categories" *ngIf="cats"><i class="fa fa-tag"></i><span
          *ngFor="let c of cats">{{category(c)}} </span></span></h2>
  </div>

  <div class="container-fluid subjects">
    <ng-container *ngIf="loader>0">
      <div class="container">
        <h2 class="heading">Subjects</h2>
      </div>
      <div class="slider">
        <div class="container">
          <!-- <ngx-slick-carousel class="carousel" [config]="subjectConfig" *ngIf="assigs"> -->
          <div class="row">
            <div *ngFor="let subject of assigs" class="col-6 col-md-3 subject">
              <app-SubjectSlideInfo [subject]="subject">
              </app-SubjectSlideInfo>
            </div>
          </div>
          <!-- </ngx-slick-carousel> -->
          <!-- <div class="arrows-prev subjects-prev"><i class="fa fa-angle-left"></i></div>
                    <div class="arrows-next subjects-next"><i class="fa fa-angle-right"></i></div> -->
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="loader==0">
      <div class="container">
        <h2 class="heading">Subjects</h2>
        <div class="loader text-center">
          <i class="fa-4x fa fa-spinner fa-pulse"></i>
          <h4>Loading subjects...</h4>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="container-fluid teachers">
    <div class="slider" *ngIf="teachers">
      <div class="container">
        <h2>Teachers</h2>
      </div>
      <div class="container">
        <div class="row carousel" *ngIf="loader>0">
          <div *ngFor="let teacher of teachers;let i = index" class="col-6 col-md-3 col-xl-3 teach">
            <app-teacherSlideInfo [profe]="teacher">
            </app-teacherSlideInfo>
          </div>
        </div>
      </div>
      <ng-container *ngIf="loader==0">
        <div class="container">
          <div class="loader text-center">
            <i class="fa-4x fa fa-spinner fa-pulse"></i>
            <h4>Loading subjects...</h4>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>