<header class="fixeds-top">
  <div class="preheader">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-6">
          <p>
            <a href="tel:+34972402258"> <i class="fa fa-phone"></i><span class="value">+34 972 40 22 58</span>
              <span>|</span></a>
            <a href="https://wa.me/34618492739"><i class="fa fa-whatsapp"></i> <span class="value">+34 618 492
                739</span> </a>
            <a href="" *ngIf="appService.user.user" (click)="logout()"> <span>|</span> <i class="fa fa-user"></i>
              Logout</a>
          </p>
        </div>
        <div class="col-6 text-right">
          <p>
            <a href="https://twitter.com/EUERAM" target="_blank"><i class="fa fa-twitter"></i></a>
            <a href="http://instagram.com/eueram" target="_blank"><i class="fa fa-instagram"></i></a>
            <a href="https://www.facebook.com/escolaeram" target="_blank"><i class="fa fa-facebook"></i></a>
            <a href="https://youtube.com/escolaeram" target="_blank"><i class="fa fa-youtube"></i></a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="header">
    <div class="container">
      <div class="row">
        <div class="col-7 col-md-3 logo">
          <img routerLink="/" src="/assets/logo/logoblanc.svg" alt="ERAM logo white">
        </div>
        <div class="col-3 col-md-7 menu text-right hide-mobile">
          <nav class="navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav justify-content-end">
              <li class="nav-item"> <a class="nav-link blanc bold" routerLink="/studies" routerLinkActive="active">
                  Studies </a> </li>
              <li class="nav-item"> <a class="nav-link blanc bold" routerLink="/team" routerLinkActive="active"> Our
                  Team </a> </li>
              <li class="nav-item dropdown menu-principal">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink">
                  About us
                </a>
                <ul class="submenu" aria-labelledby="navbarDropdownMenuLink">
                  <li><a class="" routerLink="/about/location">Location</a></li>
                  <li><a class="" routerLink="/about/udg">About UdG</a></li>
                  <li><a class="" routerLink="/about/eram">About ERAM</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown menu-principal">
                <a class="nav-link blanc bold dropdown-toggle" routerLink="/workplay" routerLinkActive="active">
                  Work&Play </a>
                <ul class="submenu" aria-labelledby="navbarDropdownMenuLink">
                  <li><a class="" routerLink="/workplay/exposition">Exhibition</a></li>
                </ul>
              </li>
              <!-- <li class="nav-item"> <a class="nav-link blanc bold"  routerLink="/contact" routerLinkActive="active"> Contact us </a> </li> -->
            </ul>

          </nav>
        </div>
        <div class="col-3 col-md-2 user hide-mobile" *ngIf="appService.user">
          <ul class="navbar-nav justify-content-end" *ngIf="appService.user.token">
            <li class="nav-item"> <a class="nav-link" routerLink="/user/favorites"> <i class="fa fa-heart"></i> </a>
            </li>
            <li class="nav-item"> | <a class="nav-link" routerLink="/user/profile"> User </a> </li>
          </ul>
          <ul class="navbar-nav justify-content-end" *ngIf="!appService.user.token">
            <li class="nav-item"><a (click)="openlogin(contentlogin)" class="nav-link">Login</a></li>
          </ul>
        </div>
        <div class="col-5 text-right header-mobile" (click)="toggleClass()">
          <span><i class="fa fa-bars"></i></span>
        </div>
        <div class="col-12 text-right nav-mobile" [ngClass]="open ? 'open' : ''" (click)="toggleClass()">
          <nav>
            <ul>
              <li><a class="" routerLink="/studies" routerLinkActive="active">Studies</a></li>
              <li><a class="" routerLink="/team" routerLinkActive="active">Our team</a></li>
              <li><a class="" routerLink="/about/location" routerLinkActive="active">About location</a></li>
              <li><a class="" routerLink="/about/udg" routerLinkActive="active">About UdG</a></li>
              <li><a class="" routerLink="/about/eram" routerLinkActive="active">About ERAM</a></li>
              <li><a class="" routerLink="/workplay" routerLinkActive="active">Work&Play</a></li>
              <li><a class="" routerLink="/workplay/exposition" routerLinkActive="active">Work&Play exhibition</a></li>
              <!-- <li><a class="" routerLink="/contact" routerLinkActive="active">Contact Us</a></li>  -->
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>



<ng-template #contentlogin let-modal>
  <div class="modal-body vimeo">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="info-medit">
      <div class="row justify-content-center">
        <div class="col-12 col-md-11">
          <h3>Login</h3>
          <form (ngSubmit)="loginUser()" *ngIf="!registerForm">
            <div class="row">
              <div class="col-12 mb-5 errors" *ngIf="errors">
                <p class="alert alert-error">{{errors.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" placeholder="email" name="email" [(ngModel)]="login.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" placeholder="password" name="password"
                  [(ngModel)]="login.password">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Login">
                <span class="btn btn-white-bg" (click)="showRegister()">Register new user</span>
              </div>
            </div>
            {{message_login | json}}
          </form>
          <form (ngSubmit)="userRegister()" *ngIf="registerForm">
            <div class="row">
              <div class="col-12 mb-5 errors" *ngIf="errors">
                <p class="alert alert-error">{{message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" placeholder="Your email" name="email"
                  [(ngModel)]="register.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" placeholder="Your password" name="password"
                  [(ngModel)]="register.password">
              </div>
              <div class="col-12">
                <input type="text" class="form-control" placeholder="Your name" name="name" [(ngModel)]="register.name">
              </div>
              <div class="col-12 col-md-7">
                <input type="text" class="form-control" placeholder="Your lastname" name="lastname"
                  [(ngModel)]="register.lastname">
              </div>
              <div class="col-12 col-md-5">
                <input type="text" class="form-control" placeholder="Your phone" name="phone"
                  [(ngModel)]="register.phone">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Register">
                <span class="btn btn-white-bg" (click)="showLogin()">Return to login</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>