import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import $ from "jquery";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-studies',
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.scss']
})
export class StudiesComponent implements OnInit {
  cats: any;
  selection: any = {};
  disabled: any = false;
  constructor(public appService: AppService, private sanitizer: DomSanitizer, private router: Router, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Studies - ERAM International');
    window.scroll(0, 0);
    this.getCategories();
    console.log('appService', this.appService.user);
    $('.course-type').hide();
  }
  getCategories(): void {
    this.appService.getCategory().subscribe(data => {
      this.cats = data;
      this.appService.userStudy = {};
    });
  }
  catname(c) {
    var ca = this.appService.categories.find(i => i.categoria_id == c);
    return ca.categoria_nom_en;
  }
  selectType(type) {
    this.selection.type = type;
    $('.circle').removeClass('selected');
    $('#' + type).addClass('selected');
    this.appService.userStudy.study = type;
    this.disabled = true;
    if (type == 'WS' || type == 'D') {
      $('.course-type').fadeOut();
    } else {
      $('.course-type').fadeIn();
    }
  }
  selectCat(cat) {
    var categories = [];
    $('#cat-' + cat).toggleClass('selected');
    $('.itinerary-selection .content').each(function () {
      if ($(this).hasClass('selected') == true) {
        var cat = $(this).attr('id');
        categories.push(cat.replace('cat-', ''));
      }
    });
    this.selection.categories = categories;
    this.appService.userStudy.categories = categories;
  }
  goToResult() {
    console.log('resultat', this.appService.userStudy);
    sessionStorage.setItem('studies_study', this.appService.userStudy.study);
    sessionStorage.setItem('studies_categories', this.appService.userStudy.categories);
    switch (this.appService.userStudy.study) {
      case 'D': this.router.navigateByUrl('/studies/search/degree'); break;
      case 'WS': this.router.navigateByUrl('/studies/search/workshop'); break;
      case '1S': this.router.navigateByUrl('/studies/search/half'); break;
      case '2S': this.router.navigateByUrl('/studies/search/half'); break;
      case '1Y': this.router.navigateByUrl('/studies/search/year'); break;
    }
    //this.router.navigateByUrl('/contact');
  }
}
