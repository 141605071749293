import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import $ from "jquery";

@Component({
  selector: 'app-year',
  templateUrl: './year.component.html',
  styleUrls: ['./year.component.scss']
})

export class YearComponent implements OnInit {
  teachers: any = [];
  subjects: any;
  modalteacher: any = {};
  modalsubject: any = {};
  moremore: Boolean = true;
  profesConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "nextArrow": '.teachers-next',
    "prevArrow": '.teachers-prev',
    "infinite": true,
    "responsive": [
      {
        "breakpoint": 768,
        "settings": {
          "slidesToShow": 2
        }
      },
      {
        "breakpoint": 1100,
        "settings": {
          "slidesToShow": 4
        }
      }
    ]
  };
  subjectConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "nextArrow": '.subjects-next',
    "prevArrow": '.subjects-prev',
    "infinite": false
  };
  subjectConfigTwo = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "nextArrow": '.subjects-next-two',
    "prevArrow": '.subjects-prev-two',
    "infinite": false
  };
  subjectConfigThree = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "nextArrow": '.subjects-next-three',
    "prevArrow": '.subjects-prev-three',
    "infinite": false
  };
  subjectConfigFour = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "nextArrow": '.subjects-next-four',
    "prevArrow": '.subjects-prev-four',
    "infinite": false
  };
  modallogin: any = {};
  loggg: any;
  errors: any;
  registerForm: Boolean = false;
  message: any;
  message_login: any;
  register = {
    email: null,
    password: null,
    name: null,
    lastname: null,
    phone: null
  };
  login = {
    email: '',
    password: ''
  }
  closeResult = '';
  assigs = [];
  loader = 0;
  constructor(public appService: AppService, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    console.log(sessionStorage.studies_study);
    console.log('teachers', this.appService.teachers);
    if (this.appService.userStudy && this.appService.userStudy.study != '') {
      this.subjects = this.appService.subjects;
      for (var i = 0; i < this.subjects.length; i++) {
        var a = this.appService.assignatures.filter(p => p.assignatura_id == this.appService.subjects[i].assignatura_fk)[0];
        if (a && a.categories) {
          var cats: any;
          if (isNaN(a.categories)) {
            cats = a.categories.split(',').map(Number);
          } else {
            cats = [a.categories];
          }
          for (var c = 0; c < this.appService.userStudy.categories.length; c++) {
            if (cats.includes(parseInt(this.appService.userStudy.categories[c]))) {
              if (this.assigs.filter(j => j.id == this.appService.subjects[i].id).length == 0) {
                this.assigs.push(this.appService.subjects[i]);
              }
            }
          }
        }
        if (i == (this.subjects.length - 1)) {
          this.loader++;
          //console.log(this.loader);
        }
        //assignatura té professors
        if (a && a.professors) {
          var teach: any;
          if (isNaN(a.professors)) {
            teach = a.professors.split(',');
          } else {
            teach = [a.professors];
          }
          for (var t = 0; t < teach.length; t++) {
            //comprovem que no estigui a l'array teachers i l'afegim
            if (!this.teachers.some(item => item.teacher_fk == parseInt(teach[t]))) {
              //console.log('aaaaa',this.teachers.some(item => item.id == parseInt(teach[t])));
              if (this.appService.teachers.filter(p => p.teacher_fk == parseInt(teach[t]))[0]) {
                this.teachers.push(this.appService.teachers.filter(p => p.teacher_fk == parseInt(teach[t]))[0]);
              }
              this.loader++;
            }
          }
        }
      }
      this.shuffle(this.teachers);
      console.log('assigs', this.assigs);
      console.log('teachers', this.teachers);
    }
    else {
      this.router.navigateByUrl('/studies');
    }
  }
  semester(num, assig) {
    console.log(num);
    console.log(assig);
    return this.appService.assignatures.filter(p => p.assignatura_id == assig && p.curs == num)[0];
  }
  shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  openTeacher(contentteacher, teacher) {
    console.log('modal slide', teacher);
    this.modalteacher.id = teacher.id;
    this.modalteacher.photo = teacher.photo;
    this.modalteacher.professor = this.appService.professors.filter(p => p.id == teacher.teacher_fk)[0];
    this.modalteacher.assignatures = [];
    this.moremore = true;
    this.appService.getTeacherWorks(this.modalteacher.professor.id).subscribe(data => {
      console.log('news', data);
    });
    $('.modal .text').addClass('more');
    //agafem assignatures i fem loop
    if (this.modalteacher.professor.subjects) {
      var subjects = this.modalteacher.professor.subjects.split(',');
      for (var j = 0; j < subjects.length; j++) {
        let assig = this.appService.assignatures.filter(p => p.assignatura_id == subjects[j]);
        this.modalteacher.assignatures.push(assig[0]);
      }
    }
    console.log('modal', this.modalteacher);
    this.modalService.open(contentteacher, { size: 'xl', scrollable: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  openSubject(contentsubject, subject) {
    console.log('modal slide', subject);
    this.modalsubject.id = subject.id;
    this.modalsubject.photo = subject.photo;
    this.modalsubject.assignatura = this.appService.assignatures.filter(p => p.assignatura_id == subject.assignatura_fk)[0];
    this.modalsubject.categories = [];
    this.moremore = true;
    $('.modal .text').addClass('more');
    //agafem assignatures i fem loop
    if (this.modalsubject.assignatura.categories) {
      var categories = this.modalsubject.assignatura.categories.split(',');
      for (var j = 0; j < categories.length; j++) {
        let cat = this.appService.categories.filter(p => p.categoria_id == categories[j]);
        // console.log('cat',cat);
        if (cat[0].categoria_nom_ca != '') {
          this.modalsubject.categories.push(cat[0]);
        }
      }
    }
    console.log('modalsubject', this.modalsubject);
    this.modalService.open(contentsubject, { size: 'xl', scrollable: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  like(id, table, user, data) {
    if (user.user.id) {
      this.appService.like(id, user.user.id, table, data).subscribe(result => {
        if (result == 200) {
          this.appService.userLikes.push({ 'table_id': id, 'table': table, 'user_id': user.user.id });
        } else {
          console.log('deleted');
          this.appService.userLikes.splice(this.appService.userLikes.findIndex(i => i.table_id == id && i.table == table), 1);
        }
      });
    }
  }
  readMore() {
    $('.modal .text').toggleClass('more');
    this.moremore = !this.moremore;
  }
  showRegister() {
    this.registerForm = true;
  }
  showLogin() {
    this.registerForm = false;
  }
  openlogin(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  userRegister() {
    console.log('form', this.register);
    if (this.register.email && this.register.name && this.register.lastname && this.register.password.length >= 5) {
      this.appService.register(this.register).subscribe(data => {
        console.log('log', data);
        this.message = data;
      });
    } else {
      this.message = 'Complete all fields';
      console.log('error');
    }
  }
  loginUser() {
    this.appService.login(this.login).subscribe(data => {
      console.log('login', data);
      this.appService.updateUser(data);
      this.message_login = data;
      this.modalService.dismissAll();
      this.appService.getUserLikes(this.appService.user.user.id).subscribe(data => {
        console.log('likeeeees', data);
        this.appService.userLikes = data;
      });
    },
      (err) => {
        console.log('err', err);
        this.errors = err;
        this.appService.user.token = null;
        this.appService.user.user = null;
      }
    );
  }
  checkLike(table, id) {
    if (this.appService.user.token) {
      var ex = this.appService.userLikes.filter(like => like.table_id == id && like.table == table);
      if (ex.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getVimeoThumb(id, vimeoid) {
    this.appService.getThumb(vimeoid).subscribe(data => {
      //console.log('da',data[0]);
      return data[0].thumbnail_large;
    });
  }
  checkCat(id) {
    return this.appService.category.find(
      c => c.category_fk == id
    );
  }
}
