import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-expo',
  templateUrl: './expo.component.html',
  styleUrls: ['./expo.component.scss']
})
export class ExpoComponent implements OnInit {
  expo: any;
  constructor(public appService: AppService, private titleService: Title) { }

  ngOnInit(): void {
    var items = ['#f14850', '#871812', '#e0e160', '#d8c28c', '#d35c8b', '#d2b413', '#da6da8'];
    this.titleService.setTitle('Poster International Exposition - ERAM International');
    this.appService.getExposition().subscribe(data => {
      console.log('expo', data);
      this.expo = data;
      if (this.expo.length > 0) {
        console.log('yes');
        if (this.expo.length < 35) {
          console.log('short');
          for (let i = this.expo.length; i < 35; i++) {
            this.expo.push({
              id: 0,
              color: items[Math.floor(Math.random() * items.length)],
              letter: null
            });
          }
        }
        //AFEGIM LES LLETRES
        this.expo.splice(4, 0, { 'id': 0, color: null, letter: 'W' });
        this.expo.splice(9, 0, { 'id': 0, color: null, letter: 'O' });
        this.expo.splice(15, 0, { 'id': 0, color: null, letter: 'R' });
        this.expo.splice(17, 0, { 'id': 0, color: null, letter: 'K' });
        this.expo.splice(20, 0, { 'id': 0, color: null, letter: '&' });
        this.expo.splice(25, 0, { 'id': 0, color: null, letter: 'P' });
        this.expo.splice(31, 0, { 'id': 0, color: null, letter: 'L' });
        this.expo.splice(33, 0, { 'id': 0, color: null, letter: 'A' });
        this.expo.splice(38, 0, { 'id': 0, color: null, letter: 'Y' });
      }
    });

  }

}
