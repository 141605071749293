<div class="medit" *ngIf="mediterranean">
  <div class="slide" (click)="open(content, mediterranean)" *ngIf="mediterranean">
    <!-- <div class="aspect" style="background-image: url({{mediterranean.vimeoinfo.thumbnail_large}});">
    </div> -->
    <div class="aspect" *ngIf="mediterranean.photo">
      <img src="https://images.eram.cat/slir/w450-c3x2-p1/mediterranean/{{ mediterranean.photo }}" (load)="loaded()"
        alt="" width="100%">
    </div>
    <div class="aspect" *ngIf="!mediterranean.photo" style="background-image: url(../../../assets/img/default.jpeg);">
    </div>
    <div class="peu text-right" *ngIf="!medit.table">
      <span class="heart" *ngIf="checkLike('mediterranean',mediterranean.id) && this.appService.user.token"><i
          class="fa fa-heart"></i></span>
      <span class="heart" *ngIf="!checkLike('mediterranean',mediterranean.id) && this.appService.user.token"><i
          class="fa fa-heart-o"></i></span>
      <span class="heart" *ngIf="!this.appService.user.token"><i class="fa fa-heart-o"></i></span>
      <span class="more">+</span>
    </div>
    <div class="peu peu-fixed text-right" *ngIf="medit.table">
      <span class="heart" *ngIf="checkLike('mediterranean',mediterranean.id) && this.appService.user.token"><i
          class="fa fa-heart"></i></span>
      <span class="heart" *ngIf="!checkLike('mediterranean',mediterranean.id) && this.appService.user.token"><i
          class="fa fa-heart-o"></i></span>
      <span class="heart" *ngIf="!this.appService.user.token"><i class="fa fa-heart-o"></i></span>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
  </div>
  <div class="modal-body vimeo">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="video-vimeo" *ngIf="modalslide">
      <div class='embed-container'>
        <div *ngIf="modalslide.vimeoid" class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item"
            [src]="'https://player.vimeo.com/video/'+modalslide.vimeoid  | safe: 'resourceUrl'"
            allowfullscreen></iframe>
        </div>
        <div *ngIf="modalslide.youtube" class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item"
            [src]="'https://www.youtube.com/embed/'+modalslide.youtube  | safe: 'resourceUrl'" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="info-medit">
      <div class="row">
        <div class="col-12 col-md-9">
          <h3 *ngIf="modalslide.title">{{modalslide.title}}</h3>
          <h4 *ngIf="modalslide.author">{{modalslide.author}}</h4>
          <p *ngIf="modalslide.description">{{modalslide.description}}</p>
        </div>
        <div class="col-12 col-md-3 text-right">
          <app-like [like]="modalslide" [table]="'mediterranean'"></app-like>
          <!-- <span class="heart" *ngIf="checkLike('mediterranean',modalslide.id) && this.appService.user.token" (click)="like(modalslide.id,'mediterranean',appService.user)"><i class="fa fa-heart fa-2x"></i></span>
            <span class="heart" *ngIf="!checkLike('mediterranean',modalslide.id) && this.appService.user.token" (click)="like(modalslide.id,'mediterranean',appService.user)"><i class="fa fa-heart-o fa-2x"></i></span>
            <span class="heart" *ngIf="!this.appService.user.token" (click)="openlogin(contentlogin)"><i class="fa fa-heart-o fa-2x"></i></span> -->

          <!-- <span class="heart" *ngIf="appService.user.token" (click)="like(modalslide.id,'mediterranean',appService.user)"><i class="fa fa-heart-o fa-2x"></i></span>
            <span class="heart" *ngIf="!appService.user.token" (click)="openlogin(contentlogin)"><i class="fa fa-heart-o fa-2x"></i></span> -->
        </div>
      </div>
    </div>
  </div>
</ng-template>