import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {
  public masonryOptions: NgxMasonryOptions = {
    gutter: 0
  };
  page: any;
  pages: any;
  constructor(public appService: AppService, private titleService: Title) { }
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.titleService.setTitle('Work & Play - ERAM International');
    this.appService.getWorkPlay().subscribe(data => {
      console.log('workplay', data);
      this.page = data;
    });
  }
  imageLoad() {
    this.masonry.layout();
  }

}
