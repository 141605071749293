<section class="degree">
  <div class="container heading">
    <h1>Degree</h1>
    <div class="text-center">
      <img src="../../../assets/img/degree.png" alt="Degree image">
    </div>
  </div>
  <div class="container info">
    <div class="row justify-content-center">
      <div class="col-12 col-md-4">
        <h3>Bachelor (degree with honours)</h3>
        <h4>Audiovisual communication and multimedia</h4>
      </div>
      <div class="col-6 col-md-2">
        <h3>Modality</h3>
        <h4>Face-to-face</h4>
      </div>
      <div class="col-6 col-md-2">
        <h3>Duration</h3>
        <h4>4 years</h4>
      </div>
      <div class="col-6 col-md-2">
        <h3>Language</h3>
        <h4>English</h4>
      </div>
      <div class="col-6 col-md-2">
        <h3>University</h3>
        <h4>University of Girona</h4>
      </div>
    </div>
  </div>
  <div class="container-fluid subjects">
    <div class="container">
      <h2 class="heading">Subjects</h2>
    </div>
    <div class="slider">
      <div class="container">
        <h3>1st course</h3>
      </div>
      <div class="container-fluid">
        <ngx-slick-carousel class="carousel" [config]="subjectConfig" *ngIf="primer">
          <div ngxSlickItem *ngFor="let subject of primer" class="subject">
            <app-SubjectSlideInfo [subject]="subject">
            </app-SubjectSlideInfo>
          </div>
        </ngx-slick-carousel>
        <div class="arrows-prev subjects-prev"><i class="fa fa-angle-left"></i></div>
        <div class="arrows-next subjects-next"><i class="fa fa-angle-right"></i></div>
      </div>
    </div>
    <div class="slider">
      <div class="container">
        <h3>2nd course</h3>
      </div>
      <div class="container-fluid">
        <ngx-slick-carousel class="carousel" [config]="subjectConfigTwo" *ngIf="segon">
          <div ngxSlickItem *ngFor="let subject of segon" class="subject">
            <app-SubjectSlideInfo [subject]="subject">
            </app-SubjectSlideInfo>
          </div>
        </ngx-slick-carousel>
        <div class="arrows-prev subjects-prev-two"><i class="fa fa-angle-left"></i></div>
        <div class="arrows-next subjects-next-two"><i class="fa fa-angle-right"></i></div>
      </div>
      <div *ngIf="!subjects">
        No teachers
      </div>
    </div>
    <div class="slider" *ngIf="tercer.length>0">
      <div class="container">
        <h3>3rd course</h3>
      </div>
      <div class="container-fluid">
        <ngx-slick-carousel class="carousel" [config]="subjectConfigThree" *ngIf="tercer.length>0">
          <div ngxSlickItem *ngFor="let subject of tercer" class="subject">
            <app-SubjectSlideInfo [subject]="subject">
            </app-SubjectSlideInfo>
          </div>
        </ngx-slick-carousel>
        <div class="arrows-prev subjects-prev-three"><i class="fa fa-angle-left"></i></div>
        <div class="arrows-next subjects-next-three"><i class="fa fa-angle-right"></i></div>
      </div>
      <div *ngIf="!subjects">
        No teachers
      </div>
    </div>
    <div class="slider" *ngIf="quart.length>0">
      <div class="container">
        <h3>4th course</h3>
      </div>
      <div class="container-fluid">
        <ngx-slick-carousel class="carousel" [config]="subjectConfigFour">
          <div ngxSlickItem *ngFor="let subject of quart" class="subject">
            <app-SubjectSlideInfo [subject]="subject">
            </app-SubjectSlideInfo>
          </div>
        </ngx-slick-carousel>
        <div class="arrows-prev subjects-prev-four"><i class="fa fa-angle-left"></i></div>
        <div class="arrows-next subjects-next-four"><i class="fa fa-angle-right"></i></div>
      </div>
      <div *ngIf="!subjects">
        No subjects
      </div>
    </div>
  </div>
  <div class="container-fluid teachers">
    <div class="slider" *ngIf="teachers">
      <div class="container">
        <h2>Teachers</h2>
      </div>
      <div class="container-fluid">
        <ngx-slick-carousel class="carousel" [config]="profesConfig">
          <div ngxSlickItem *ngFor="let teacher of teachers;let i = index" class="teach">
            <app-teacherSlideInfo [profe]="teacher" *ngIf="teacher.id>0"></app-teacherSlideInfo>
          </div>
        </ngx-slick-carousel>
        <div class="arrows-prev teachers-prev"><i class="fa fa-angle-left"></i></div>
        <div class="arrows-next teachers-next"><i class="fa fa-angle-right"></i></div>
      </div>
      <div *ngIf="!teachers">
        No teachers
      </div>
    </div>
  </div>
</section>





<ng-template #contentteacher let-modal>
  <div class="modal-teacher">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-md-6 info">
          <!-- <div class="categories">
              <span><i class="fa fa-user"></i></span>
              <span><i class="fa fa-user"></i></span>
              <span><i class="fa fa-user"></i></span>
              <small>(no dinàmic)</small>
            </div> -->
          <h3>{{modalteacher.professor.nom}} {{modalteacher.professor.cog1}} {{modalteacher.professor.cog2}}</h3>
          <h4 class="frase" *ngIf="modalteacher.slogan">{{modalteacher.slogan}}</h4>
          <div class="text" [ngClass]="{'more' : modalteacher.professor.curriculum.en.length > 650}"
            [innerHTML]="modalteacher.professor.curriculum.en"></div>
          <div class="read-more text-right" *ngIf="modalteacher.professor.curriculum.en.length > 650"
            (click)="readMore()">
            <span class="moremore" *ngIf="moremore">+</span>
            <span class="moreless" *ngIf="!moremore">-</span>
          </div>
          <div class="subjects text-left" *ngIf="modalteacher.professor.subjects">
            <h4>Subjects</h4>
            <p *ngIf="modalteacher.assignatures.length>0">
              <span *ngFor="let a of modalteacher.assignatures">
                <ng-container *ngIf="a">
                  <span *ngIf="a.title.en"><a
                      href="https://www.eram.cat/en/studies/degree-in-audiovisual-communication-and-multimedia/study-plan-gcam/{{a.mr.en}}"
                      target="_blank">{{a.title.en}}</a></span>
                  <span *ngIf="!a.title.en"><a
                      href="https://www.eram.cat/ca/estudis/grau-en-comunicacio-audiovisual-i-multimedia/pla-destudis-gcam/{{a.mr.ca}}"
                      target="_blank">{{a.title.ca}}</a></span>
                </ng-container>
              </span>
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 text-right">
          <div class="foto">
            <img src="https://images.eram.cat/slir/w650/persona/{{modalteacher.photo}}" *ngIf="modalteacher.photo">
            <img src="https://images.eram.cat/slir/w650/persona/{{modalteacher.professor.foto.url}}"
              *ngIf="!modalteacher.photo && modalteacher.professor.foto.url">
          </div>
        </div>
        <div class="col-12">
          <form>
            <div class="row align-items-center">
              <div class="col-12 col-md-3">
                <h5>What's your favourite place?</h5>
              </div>
              <div class="col-12 col-md-6">
                <input type="text" class="form-control" placeholder="Type place">
              </div>
              <div class="col-12 col-md-3">
                <input type="submit">
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 related">
          <h4>Related</h4>
          fdsfdsaf
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentlogin let-modallogin>
  <div class="modal-body vimeo">
    <button type="button" class="close" aria-label="Close" (click)="modallogin.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="info-medit">
      <div class="row justify-content-center">
        <div class="col-12 col-md-11">
          <h3>Login</h3>
          <form (ngSubmit)="loginUser()" *ngIf="!registerForm">
            <div class="row">
              <div class="col-12 mb-3 errors" *ngIf="errors">
                <p class="alert alert-error">{{errors.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" placeholder="email" name="email" [(ngModel)]="login.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" placeholder="password" name="password"
                  [(ngModel)]="login.password">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Login">
                <span class="btn btn-white-bg" (click)="showRegister()">Register new user</span>
              </div>
            </div>
            {{message_login | json}}
          </form>
          <form (ngSubmit)="userRegister()" *ngIf="registerForm">
            <div class="row">
              <div class="col-12 mb-3 errors" *ngIf="message">
                <p class="alert alert-error" *ngIf="!message.message">{{message}}</p>
                <p class="alert alert-error" *ngIf="message.message">{{message.message}}</p>
              </div>
              <div class="col-12 col-md-6">
                <input type="email" class="form-control" required placeholder="Your email" name="email"
                  [(ngModel)]="register.email">
              </div>
              <div class="col-12 col-md-6">
                <input type="password" class="form-control" required placeholder="Your password" name="password"
                  [(ngModel)]="register.password">
              </div>
              <div class="col-12">
                <input type="text" class="form-control" required placeholder="Your name" name="name"
                  [(ngModel)]="register.name">
              </div>
              <div class="col-12 col-md-7">
                <input type="text" class="form-control" placeholder="Your lastname" name="lastname"
                  [(ngModel)]="register.lastname">
              </div>
              <div class="col-12 col-md-5">
                <input type="text" class="form-control" placeholder="Your phone" name="phone"
                  [(ngModel)]="register.phone">
              </div>
              <div class="col-12">
                <input type="submit" class="btn btn-white" value="Register">
                <span class="btn btn-white-bg" (click)="showLogin()">Return to login</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>