import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';
import { StudiesComponent } from './studies/studies.component';
import { WorkshopComponent } from './studies/workshop/workshop.component';
import { YearComponent } from './studies/year/year.component';
import { HalfComponent } from './studies/half/half.component';
import { DegreeComponent } from './studies/degree/degree.component';
import { ContactComponent } from './contact/contact.component';
import { ProfileComponent } from './user/profile/profile.component';
import { FavoritesComponent } from './user/favorites/favorites.component';
import { LocationComponent } from './about/location/location.component';
import { UdgComponent } from './about/udg/udg.component';
import { EramComponent } from './about/eram/eram.component';
import { WorkComponent } from './work/work.component';
import { WorkDetailComponent } from './work-detail/work-detail.component';
import { ExpoComponent } from './expo/expo.component';
import { ExpoDetailComponent } from './expo-detail/expo-detail.component';

const routes: Routes = [
  //{ path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'team', component: TeamComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'studies', component: StudiesComponent },
  { path: 'studies/search/workshop', component: WorkshopComponent },
  { path: 'studies/search/degree', component: DegreeComponent },
  { path: 'studies/search/year', component: YearComponent },
  { path: 'studies/search/half', component: HalfComponent },
  { path: 'about/location', component: LocationComponent },
  { path: 'about/udg', component: UdgComponent },
  { path: 'about/eram', component: EramComponent },
  { path: 'workplay', component: WorkComponent },
  { path: 'workplay/exposition', component: ExpoComponent },
  { path: 'workplay/exposition/:id', component: ExpoDetailComponent },
  { path: 'workplay/:id', component: WorkDetailComponent },
  { path: 'user', redirectTo: 'user/profile', pathMatch: 'full' },
  { path: 'user/profile', component: ProfileComponent },
  { path: 'user/favorites', component: FavoritesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
