import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from '../app.service';
import $ from "jquery";

@Component({
  selector: 'app-SubjectSlideInfo',
  templateUrl: './SubjectSlideInfo.component.html',
  styleUrls: ['./SubjectSlideInfo.component.scss']
})
export class SubjectSlideInfoComponent implements OnInit {
  @Input() subject: any;
  assignatura: any;

  modallogin: any = {};
  loggg: any;
  errors: any;
  registerForm: Boolean = false;
  message: any;
  message_login: any;
  register = {
    email: null,
    password: null,
    name: null,
    lastname: null,
    phone: null
  };
  login = {
    email: '',
    password: ''
  }
  closeResult = '';
  assigs = [];
  loader = 0;
  modalteacher: any = {};
  modalsubject: any = {};
  moremore: Boolean = true;
  sem: any;
  cats: any;

  constructor(
    private route: ActivatedRoute, private modalService: NgbModal,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    // console.log('input',this.subject.assignatura_fk);
    this.assignatura = this.appService.assignatures.filter(p => p.assignatura_id == this.subject.assignatura_fk);
    this.assignatura = this.assignatura[0];
    //console.log('ass', this.assignatura);
  }
  category(c) {
    var ca = this.appService.categories.filter(a => a.categoria_id == parseInt(c));
    return ca[0].categoria_nom_en;
  }
  semester(num, assig) {
    console.log('assig', assig);
    return this.appService.assignatures.filter(p => p.assignatura_id == assig && p.curs == num)[0];
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  openSubject(contentsubject, subject) {
    console.log('modal slide', subject);
    this.modalsubject.id = subject.id;
    this.modalsubject.photo = subject.photo;
    this.modalsubject.skills = subject.skills;
    this.modalsubject.career = subject.career;
    this.modalsubject.assignatura = this.appService.assignatures.filter(p => p.assignatura_id == subject.assignatura_fk)[0];
    this.modalsubject.categories = [];
    //this.moremore = true;
    // $('.modal .text').addClass('more');
    //agafem assignatures i fem loop
    if (this.modalsubject.assignatura.categories) {
      var categories = this.modalsubject.assignatura.categories.split(',');
      for (var j = 0; j < categories.length; j++) {
        let cat = this.appService.categories.filter(p => p.categoria_id == categories[j]);
        if (cat[0].categoria_nom_ca != '') {
          this.modalsubject.categories.push(cat[0]);
        }
        if (cat[0].categoria_id == 33) {
          this.modalsubject.categories = [cat[0]];
          break;
        }
      }

    }
    console.log('modalsubject', this.modalsubject);
    this.modalService.open(contentsubject, { size: 'xl', scrollable: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  like(id, table, user, data) {
    if (user.user.id) {
      this.appService.like(id, user.user.id, table, data).subscribe(result => {
        if (result == 200) {
          this.appService.userLikes.push({ 'table_id': id, 'table': table, 'user_id': user.user.id });
        } else {
          console.log('deleted');
          this.appService.userLikes.splice(this.appService.userLikes.findIndex(i => i.table_id == id && i.table == table), 1);
        }
      });
    }
  }
  // readMore() {
  //   $('.modal .text').toggleClass('more');
  //   this.moremore = !this.moremore;
  // }
  showRegister() {
    this.registerForm = true;
  }
  showLogin() {
    this.registerForm = false;
  }
  openlogin(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  userRegister() {
    console.log('form', this.register);
    if (this.register.email && this.register.name && this.register.lastname && this.register.password.length >= 5) {
      this.appService.register(this.register).subscribe(data => {
        console.log('log', data);
        this.message = data;
      });
    } else {
      this.message = 'Complete all fields';
      console.log('error');
    }
  }
  loginUser() {
    this.appService.login(this.login).subscribe(data => {
      console.log('login', data);
      this.appService.updateUser(data);
      this.message_login = data;
      this.modalService.dismissAll();
      this.appService.getUserLikes(this.appService.user.user.id).subscribe(data => {
        console.log('likeeeees', data);
        this.appService.userLikes = data;
      });
    },
      (err) => {
        console.log('err', err);
        this.errors = err;
        this.appService.user.token = null;
        this.appService.user.user = null;
      }
    );
  }

  checkCat(id) {
    return this.appService.category.find(
      c => c.category_fk == id
    );
  }
}
