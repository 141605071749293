<section class="workplay" *ngIf="page">
    <div class="container">
        <h1>Work & Play</h1>
        <span class="underline u2x"></span>
    </div>
    <div class="container">
        <ngx-masonry [ordered]="true" [options]="masonryOptions" class="row" *ngIf="page">
            <div ngxMasonryItem class="col-12 col-sm-6 col-md-4 col-xl-3 item" *ngFor="let w of page">
                <div class="content" masonryLazy routerLink="/workplay/{{w.id}}" *ngIf="w.id>0">
                    <img (load)="imageLoad()" src="https://images.eram.cat/slir/w430-p1/workplay/{{w.photo}}">
                    <div class="footer">
                        <h2>{{w.title}}</h2>
                    </div>
                </div>
            </div>
        </ngx-masonry>
    </div>
</section>